/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-24 14:04:11
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2018-05-03 08:22:51
 */

import { LOCATION_CHANGE } from 'react-router-redux';
import queryString from 'query-string';
import LocalStorage from '../../common/LocalStorage';
import { LocalStorageKeys } from "../../common/Constants";

export default function reducer(
  state = {
    clientId: null,
    email: null,
    goto: null,
  },
  action,
) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      LocalStorage.setItem(LocalStorageKeys.publicSession, false)
      const qs = queryString.parse(action.payload.search);
      return {
        ...state,
        clientId: qs.clientId,
        email: qs.email,
        goto: qs.goto,
        relayState: qs.state,
      };
    }
    default:
      return state;
  }
}
