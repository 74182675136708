import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { MessageType } from '../common/Constants';
import * as Util from '../common/Util';
import * as UserActions from '../redux/actions/userActions';
import LoaderOverlay from '../components/LoaderOverlay';
import Message from '../components/Message';
import QuickLinks from '../components/QuickLinks';
import RegistrationForm from '../components/forms/RegistrationForm';

class RegisterAccount extends Component {
  onSubmit = (user) => {
    const {
      createUser, createHoneyPot, i18n, clientId, relayState,
    } = this.props;
    if (user.honeyPot && user.honeyPot !== '') {
      createHoneyPot();
    } else {
      createUser(user, clientId, i18n.language, relayState);
    }
  };

  render() {
    const {
      t, successMessage, errorMessage, isFetching, requestId,
    } = this.props;
    document.title = t('Tableau Account Registration');

    return (
      <div>
        {!successMessage && (
          <div className="bg--light-gray-1 section section--padded-two">
            <div className="content-container text--centered">
              <h1>{t('Create your Tableau Account')}</h1>
            </div>
          </div>
        )}
        <section className="section">
          <div className="content-container" id="loading-overlay-content">
            {isFetching && <LoaderOverlay />}
            {errorMessage && (
              <span>
                <Message id="errorMessage" text={Util.translateArray(errorMessage)} messageType={MessageType.error} />
                <br />
              </span>
            )}
            {!successMessage && <RegistrationForm onSubmit={this.onSubmit} isFetching={isFetching} />}

            {successMessage && (
              <div>
                <Message
                  id="successMessage"
                  text={Util.translateArray(successMessage)}
                  messageType={MessageType.notice}
                />
                <QuickLinks />
              </div>
            )}
            <div className="clear" />
          </div>
          <input type="hidden" id="requestId" value={requestId} />
        </section>
      </div>
    );
  }
}

RegisterAccount.propTypes = {
  t: PropTypes.func.isRequired,
  successMessage: PropTypes.array,
  errorMessage: PropTypes.array,
  isFetching: PropTypes.bool,
  createUser: PropTypes.func.isRequired,
  createHoneyPot: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  clientId: PropTypes.string,
  requestId: PropTypes.string,
  relayState: PropTypes.string,
};

RegisterAccount.defaultProps = {
  errorMessage: null,
  successMessage: null,
  isFetching: false,
  clientId: null,
  requestId: '',
  relayState: null,
};

const mapStateToProps = state => ({
  successMessage: state.registrationReducer.successMessage,
  errorMessage: state.registrationReducer.errorMessage,
  isFetching: state.registrationReducer.isFetching,
  clientId: state.locationReducer.clientId,
  relayState: state.locationReducer.relayState,
  requestId: state.registrationReducer.requestId,
});

const mapDispatchToProps = dispatch => ({
  createUser: bindActionCreators(UserActions.createUser, dispatch),
  createHoneyPot: bindActionCreators(UserActions.createHoneyPot, dispatch),
});
export default translate()(connect(mapStateToProps, mapDispatchToProps)(RegisterAccount));
