/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:20:43
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2018-01-25 16:39:53
 */
import { applyMiddleware, createStore } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension/logOnlyInProduction';
import { logger } from 'redux-logger';
import thunk from 'redux-thunk';
import promise from 'redux-promise-middleware';
import { routerMiddleware } from 'react-router-redux';

import History from '../common/History';
import reducer from './reducers';

const middlewares = [promise(), thunk, routerMiddleware(History)];
let store = null;

if (window.REACT_APP_DEBUG === 'true') {
  middlewares.push(logger);
  store = createStore(reducer, composeWithDevTools(applyMiddleware(...middlewares)));
} else {
  store = createStore(reducer, applyMiddleware(...middlewares));
}
const defaultStore = store;
export default defaultStore;
