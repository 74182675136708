/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:22:43
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-29 08:35:28
 */
import { withFormsy } from 'formsy-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { mapFields, getCountryConfig } from '../../../common/AddressTool';
import getCountries from '../../../common/getCountries';
import Select from './Select';
import ValidationInput from './ValidationInput';
import { ErrorMessages } from '../../../common/Constants';
import { t } from 'i18next';

class AddressField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedCountry: '',
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.config !== nextProps.config) {
      const country = getCountryConfig(nextProps.config, this.state.selectedCountry);
      const fields = country && mapFields(country.fields);
      this.setState({ fields });
    }
  }

  handleCountryChange = (iso) => {
    let fields = null;
    if (iso) {
      const country = getCountryConfig(this.props.config, iso);
      if (country) {
        fields = mapFields(country.fields);
      }
    }
    this.setState({ fields, selectedCountry: iso });
  };

  render() {
    const { config, userModel } = this.props;
    return (
      <div className="form__field form-field has-float-label">
        <Select
          id="country"
          name="country"
          title={config.label}
          options={getCountries(config)}
          onChange={this.handleCountryChange}
          value={userModel.country}
          translateContents={false}
        />
        {this.state.fields
          && this.state.fields.map((field) => {
            if (field.options) {
              return (
                <Select
                  key={field.type + field.options}
                  id={field.type}
                  name={field.type}
                  title={field.label}
                  options={field.options}
                  value={userModel[field.type]}
                  translateContents={false}
                />
              );
            }
            if (field.format) {
              return (
                <ValidationInput
                  key={field.type + field.options}
                  id={field.type}
                  maxLength="100"
                  name={field.type}
                  placeholder={field.label}
                  title={field.label}
                  validations="validAddressField"
                  validationError={`(eg. ${field.example})`}
                  value={userModel[field.type]}
                />
              );
            }
            return (
              <ValidationInput
                key={field.type + field.options}
                id={field.type}
                maxLength="100"
                name={field.type}
                placeholder={field.label}
                type="text"
                title={field.label}
                value={userModel[field.type]}
                validations="validSpecialCharacters"
                validationError={t(ErrorMessages.invalidField)}
              />
            );
          })}
      </div>
    );
  }
}

AddressField.propTypes = {
  config: PropTypes.object.isRequired,
  userModel: PropTypes.object.isRequired,
};

AddressField.defaultProps = {};

export default withFormsy(AddressField);
