/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 08:18:21
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-13 19:33:20
 */

// #region imports
import { PropTypes } from "prop-types";
import { Route, Switch, Redirect } from "react-router";
import { translate } from "react-i18next";
import queryString from "query-string";
import React from "react";
import { ConnectedRouter } from "react-router-redux";

import "./assets/css/marketing.css";
import "./assets/css/style.min.css";
import "./assets/css/font-awesome.min.css";
import "./common/Validators";

import {
  ADDITIONAL_INFO_ROUTE,
  ADMIN_ROUTE,
  CALLBACK_ROUTE,
  CHANGEEMAIL_ROUTE,
  CHANGEMYEMAIL_ROUTE,
  CHANGEPASSWORD_ROUTE,
  ERROR_ROUTE,
  HELP_ROUTE,
  HOME_ROUTE,
  LOGIN_ROUTE,
  MANAGEACCOUNT_ROUTE,
  REGISTER_ROUTE,
  RESEND_ROUTE,
  RESETPASSWORD_ROUTE,
  SEARCH_ROUTE,
  SIGN_ME_OUT_ROUTE,
  SIGNOUT_ROUTE,
  SILENTCALLBACK_ROUTE,
  TOKEN_ROUTE,
  UNAUTHORIZED_ROUTE
} from "./routes";
import { clearSession, getProfile } from "./common/SessionManager";
import { TokenTypes } from "./common/Constants";
import AccountAdmin from "./pages/AccountAdmin";
import AdditionalInfo from "./pages/AdditionalInfo";
import Auth from "./common/Auth";
import ChangeEmailPage from "./pages/ChangeEmail";
import ChangeMyEmail from "./pages/ChangeMyEmail";
import ChangePassword from "./pages/ChangePassword";
import ErrorBoundry from "./components/ErrorBoundry";
import ErrorPage from "./pages/ErrorPage";
import HelpPage from "./pages/Help";
import History from "./common/History";
import HomePage from "./pages/Home";
import Layout from "./components/layout/Layout";
import Loader from "./components/Loader";
import MyUserProfile from "./pages/MyUserProfile";
import RegisterAccount from "./pages/RegisterAccount";
import RequireLogin from "./components/RequireLogin";
import ResendToken from "./pages/ResendToken";
import SignOut from "./pages/SignOut";
import TokenPage from "./pages/Token";
import Unauthorized from "./pages/Unauthorized";
import UserSearch from "./pages/UserSearch";
import { logInfo } from "./common/Logger";
// #endregion

const auth = new Auth();

const handleAuthentication = nextState => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleAuthentication();
  }
};

const handleSilentAuthentication = nextState => {
  if (/access_token|id_token|error/.test(nextState.location.hash)) {
    auth.handleSilentAuthentication();
  }
};

const showError = (parsed, query) => (
  <Layout>
    <ErrorBoundry>
      <ErrorPage
        error={parsed.error}
        errorDescription={parsed.error_description}
        clientId={parsed.client_id}
        queryString={query}
      />
    </ErrorBoundry>
  </Layout>
);

const App = () => (
  <ConnectedRouter component={App} history={History}>
    <div>
      <Switch>
        <Route
          exact
          path={HOME_ROUTE}
          render={props => (
            <RequireLogin {...props}>
              <Layout>
                <HomePage />
              </Layout>
            </RequireLogin>
          )}
        />
        <Route
          exact
          path={LOGIN_ROUTE}
          render={props => (
            <RequireLogin {...props}>
              <Layout>
                <HomePage />
              </Layout>
            </RequireLogin>
          )}
        />
        <Route
          path={ADMIN_ROUTE}
          render={props => (
            <RequireLogin profileAdmin profileReader {...props}>
              <Layout>
                <ErrorBoundry>
                  <AccountAdmin {...props} />
                </ErrorBoundry>
              </Layout>
            </RequireLogin>
          )}
        />
        <Route
          path={SEARCH_ROUTE}
          render={props => (
            <RequireLogin profileAdmin profileReader {...props}>
              <Layout>
                <ErrorBoundry>
                  <UserSearch />
                </ErrorBoundry>
              </Layout>
            </RequireLogin>
          )}
        />
        <Route
          path={CHANGEEMAIL_ROUTE}
          render={props => (
            <RequireLogin profileAdmin {...props}>
              <Layout>
                <ErrorBoundry>
                  <ChangeEmailPage />
                </ErrorBoundry>
              </Layout>
            </RequireLogin>
          )}
        />
        <Route
          path={CHANGEMYEMAIL_ROUTE}
          render={props => {
            const profile = getProfile();
            const showChangeMyEmail = () => (
              <div>
                <RequireLogin {...props}>
                  <Layout>
                    <ErrorBoundry>
                      <ChangeMyEmail />
                    </ErrorBoundry>
                  </Layout>
                </RequireLogin>
              </div>
            );
            const handleOnlineUser = () => {
              logInfo({
                uid: profile.uid,
                eventType: "redirectChangeEmail",
                message:
                  "Redirected Change Email to https://kb.tableau.com/articles/HowTo/changing-tableau-online-user-name"
              });
              window.location =
                "https://kb.tableau.com/articles/HowTo/changing-tableau-online-user-name";
            };
            const isOnlineUser =
              profile &&
              !profile.profileAdmin &&
              !profile.profileReader &&
              profile.isTableauOnline;
            if (!profile) {
              return (
                  <RequireLogin changeMyEmailRoute { ...props}>
                      <Layout>
                          <ErrorBoundry>
                              <ChangeMyEmail />
                          </ErrorBoundry>
                      </Layout>
                  </RequireLogin>
              );
            }
            if (isOnlineUser) {
              handleOnlineUser();
            }
            return <div>{!isOnlineUser && showChangeMyEmail()}</div>;
          }}
        />
        <Route
          path={CHANGEPASSWORD_ROUTE}
          render={props => (
            <RequireLogin {...props}>
              <Layout>
                <ErrorBoundry>
                  <ChangePassword />
                </ErrorBoundry>
              </Layout>
            </RequireLogin>
          )}
        />
        <Route
          path={MANAGEACCOUNT_ROUTE}
          render={props => (
            <RequireLogin {...props}>
              <Layout>
                <ErrorBoundry>
                  <MyUserProfile />
                </ErrorBoundry>
              </Layout>
            </RequireLogin>
          )}
        />
        <Route
          path={REGISTER_ROUTE}
          render={() => (
            <Layout>
              <ErrorBoundry>
                <RegisterAccount />
              </ErrorBoundry>
            </Layout>
          )}
        />
        <Route
          path={ADDITIONAL_INFO_ROUTE}
          render={() => {
            const isEmpty = value =>
              value === null || value.trim().length === 0;
            const hasParams = () => {
              // debugger;
              const params = new URLSearchParams(window.location.search);
              return (
                !isEmpty(params.get("state")) &&
                !isEmpty(params.get("uid")) &&
                !isEmpty(params.get("token")) &&
                !isEmpty(params.get("email")) &&
                !isEmpty(params.get("country"))
              );
            };
            const showAdditionalInfo = () => (
              <Layout>
                <ErrorBoundry>
                  <AdditionalInfo />
                </ErrorBoundry>
              </Layout>
            );
            const notFoundErrorPage = () => (
                <Layout>
                    <ErrorPage code="404" />
                </Layout>
            );
            if (!hasParams()) {
                return notFoundErrorPage();
            }
            return showAdditionalInfo();
          }}
        />
        <Route
          path={SIGNOUT_ROUTE}
          render={() => {
            clearSession();
            return (
              <Layout>
                <SignOut />
              </Layout>
            );
          }}
        />
        <Route
          path={TOKEN_ROUTE}
          render={({ match }) => (
            <Layout>
              <ErrorBoundry>
                <TokenPage match={match} />
              </ErrorBoundry>
            </Layout>
          )}
        />
        <Route
          path={RESEND_ROUTE}
          render={props => (
            <Layout>
              <ErrorBoundry>
                <ResendToken
                  tokenType={TokenTypes.RESEND_ACTIVATION}
                  {...props}
                />
              </ErrorBoundry>
            </Layout>
          )}
        />
        <Route
          path={UNAUTHORIZED_ROUTE}
          render={props => {
            const parsed = queryString.parse(props.location.search);
            return (
              <Layout>
                <Unauthorized message={parsed.message} email={parsed.email} />
              </Layout>
            );
          }}
        />
        <Route
          path={RESETPASSWORD_ROUTE}
          render={() => (
            <Layout>
              <ErrorBoundry>
                <ResendToken tokenType={TokenTypes.RESET_PASSWORD} />
              </ErrorBoundry>
            </Layout>
          )}
        />
        <Route
          path={CALLBACK_ROUTE}
          render={props => {
            if (/error/.test(props.location.hash)) {
              const parsed = queryString.parse(props.location.hash);
              return <div>{showError(parsed, props.location.hash)}</div>;
            }
            try {
              handleAuthentication(props);
            } catch (err) {
              throw err;
            }
            return <Loader {...props} />;
          }}
        />
        <Route
          path={SILENTCALLBACK_ROUTE}
          render={props => {
            handleSilentAuthentication(props);
            return <Loader {...props} />;
          }}
        />
        <Route
          path={SIGN_ME_OUT_ROUTE}
          render={({ location }) => {
            const parsed = queryString.parse(location.search);
            auth.logout(parsed.goto, parsed.clientId);
            return null;
          }}
        />
        <Route
          path={HELP_ROUTE}
          render={() => (
            <Layout>
              <ErrorBoundry>
                <HelpPage />
              </ErrorBoundry>
            </Layout>
          )}
        />
        <Route
          path={ERROR_ROUTE}
          render={props => {
            const parsed = queryString.parse(props.location.search);
            let redirect = false;
            if (
              parsed.error_description &&
              parsed.error_description.includes("Allowed Logout URLs")
            ) {
              redirect = true;
            }
            function logoutAndRedirect() {
              let urlLink = "https://www.tableau.com";
              if (parsed.error_description.includes("community.tableau.com")) {
                urlLink = "https://community.tableau.com";
              }
              auth.logout(urlLink);
            }
            return (
              <div>
                {redirect
                  ? logoutAndRedirect()
                  : showError(parsed, props.location.search)}
              </div>
            );
          }}
        />
        <Route
          path="/user/activateNewPassword"
          render={({ location }) => (
            <Redirect to={`/token/${queryString.parse(location.search).t}`} />
          )}
        />
        <Route
          path="/user/activateEmailOnly"
          render={({ location }) => (
            <Redirect to={`/token/${queryString.parse(location.search).t}`} />
          )}
        />
        <Route
          path="/user/activate"
          render={({ location }) => (
            <Redirect to={`/token/${queryString.parse(location.search).t}`} />
          )}
        />
        <Route
          path="/user/resetPasswordToken"
          render={({ location }) => (
            <Redirect to={`/token/${queryString.parse(location.search).t}`} />
          )}
        />
        <Redirect from="/user/changeTableauID" to={CHANGEEMAIL_ROUTE} />
        <Redirect from="/user/updateTableauID" to={CHANGEEMAIL_ROUTE} />
        <Redirect from="/user/logout" to={SIGN_ME_OUT_ROUTE} />
        <Redirect from="/user/resetPasswordEmail" to={RESETPASSWORD_ROUTE} />
        <Route
          path="/user/:subpath"
          render={({ match }) => <Redirect to={`/${match.params.subpath}`} />}
        />
        {/* NO MATCH ROUTE */}
        <Route
          render={() => (
            <Layout>
              <ErrorPage code="404" />
            </Layout>
          )}
        />
      </Switch>
    </div>
  </ConnectedRouter>
);

App.propTypes = {
  location: PropTypes.object
};

App.defaultProps = {
  location: null
};
export default translate()(App);
