export const ADDITIONAL_INFO_ROUTE = '/additionalInfo';
export const ADMIN_ROUTE = '/admin';
export const CALLBACK_ROUTE = '/callback';
export const CHANGEEMAIL_ROUTE = '/changeEmail';
export const CHANGEMYEMAIL_ROUTE = '/changeMyEmail';
export const CHANGEPASSWORD_ROUTE = '/changePassword';
export const ERROR_ROUTE = '/error';
export const HELP_ROUTE = '/help';
export const HOME_ROUTE = '/';
export const LOGIN_ROUTE = '/login';
export const SIGNOUT_ROUTE = '/signout';
export const SIGN_ME_OUT_ROUTE = '/signmeout';
export const MANAGEACCOUNT_ROUTE = '/profile';
export const REGISTER_ROUTE = '/register';
export const RESEND_ROUTE = '/resendActivation';
export const RESETPASSWORD_ROUTE = '/resetPassword';
export const SEARCH_ROUTE = '/search';
export const SILENTCALLBACK_ROUTE = '/silentcallback';
export const TOKEN_ROUTE = '/token/:tokenId';
export const UNAUTHORIZED_ROUTE = '/unauthorized';
