import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

const PublicErrorHandling = (props) => {
    const {
        t
    } = props;
    const [seconds, setSeconds] = React.useState(5);
    const PUBLIC_URL= 'https://public.tableau.com/'
    React.useEffect(() => {
        if (seconds > 0) {
            setTimeout(() => setSeconds(seconds - 1), 1000);
        } else {
            window.location.href = PUBLIC_URL;
        }
    });
     return (
        <div className="text--link">
            <br/>
            {`${t('You will be automatically redirected to Tableau Public in ')}`}  <strong>{seconds}</strong> {`${t('seconds')}`}.  <br/>
            {`${t('Please try logging in again.')}`}
        </div>
     )
};

PublicErrorHandling.prototypes = {
    t: PropTypes.func.isRequired,
}
export default translate()(PublicErrorHandling);