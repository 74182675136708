const getCountries = (config) => {
  const countries = config.options.map((country, index) => {
    const result = {};
    result.id = (index + 1).toString();
    result.label = country.label;
    result.value = country.iso;
    return result;
  });

  countries.unshift({ id: '0', value: '', label: config.label });
  return countries;
};

export default getCountries;
