import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import Formsy from 'formsy-react';
import { UserSearchGroups } from '../../common/Data';
import { ErrorMessages } from '../../common/Constants';
import ValidationInput from './elements/ValidationInput';
import Input from './elements/Input';
import Select from './elements/Select';

class UserSearchForm extends Component {
  constructor(props) {
    super(props);
    this.state = { submitDisabled: false };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.setState({ submitDisabled: false });
    }
  }

  onValidSubmit = (formData) => {
    this.setState({ submitDisabled: true });
    this.props.onSubmit(formData);
  };

  mapInputs = inputs => ({
    uid: inputs.uid,
    email: inputs.email,
    firstName: inputs.firstName,
    lastName: inputs.lastName,
    phone: inputs.phone,
    company: inputs.company,
    group: inputs.group,
  });

  render() {
    const { t, showGroups } = this.props;
    return (
      <div id="form" className="clearfix">
        <h1 className="title">{t('Profile Search')}</h1>
        <Formsy
          className="form--full-width"
          onSubmit={this.onValidSubmit}
          onInvalidSubmit={this.onInvalidSubmit}
          mapping={this.mapInputs}
          noValidate
        >
          <div className="section section--padded-one clearfix">
            <div className="grid--4">
              <ValidationInput
                id="uid"
                maxLength="100"
                name="uid"
                placeholder={t('UID')}
                type="text"
                title={t('UID')}
                value=""
                validations="validStringSearch"
                validationError={t(ErrorMessages.invalidWildCardSearch)}
              />
              <Input
                autoFocus
                id="email"
                maxLength="100"
                name="email"
                placeholder={t('Email')}
                type="text"
                required
                title={t('Email')}
                value=""
              />
            </div>
            <div className="grid--4">
              <ValidationInput
                id="firstName"
                maxLength="100"
                name="firstName"
                placeholder={t('First Name')}
                type="text"
                title={t('First Name')}
                value=""
                validations="validNameSearch"
                validationError={t(ErrorMessages.invalidNameSearch)}
              />
              <ValidationInput
                id="lastName"
                maxLength="100"
                name="lastName"
                placeholder={t('Last Name')}
                type="text"
                title={t('Last Name')}
                value=""
                validations="validNameSearch"
                validationError={t(ErrorMessages.invalidNameSearch)}
              />
            </div>
            <div className="grid--4">
              <ValidationInput
                id="phone"
                maxLength="100"
                name="phone"
                placeholder={t('Phone')}
                type="text"
                title={t('Phone')}
                value=""
                validations="validNumberSearch"
                validationError={t(ErrorMessages.invalidNumberSearch)}
              />
              <ValidationInput
                id="company"
                maxLength="100"
                name="company"
                placeholder={t('Company')}
                type="text"
                title={t('Company')}
                value=""
                validations="validStringSearch"
                validationError={t(ErrorMessages.invalidWildCardSearch)}
              />
              {showGroups && <Select id="group" name="group" title="Group" options={UserSearchGroups} />}
            </div>
          </div>
          <div className="form-buttons text--right">
            <p className="label-tag">{t('The * character is allowed as a wild card')}</p>
            <button type="submit" className="cta cta--secondary" id="searchButton" disabled={this.state.submitDisabled}>
              {t('Search')}
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}
UserSearchForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  showGroups: PropTypes.bool,
  isFetching: PropTypes.bool.isRequired,
};

UserSearchForm.defaultProps = {
  showGroups: false,
};
export default translate()(UserSearchForm);
