/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:22:06
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-26 12:16:41
 */
import React, { Component } from "react";
import { translate } from "react-i18next";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import { HELP_ROUTE } from "../../routes";
import { Language } from "../../common/Constants";
import LanguageSelector from "./LanguageSelector";

const year = new Date().getFullYear();

class Footer extends Component {
  constructor(props) {
    super(props);
    this.selectedLangInline = "en-us";
  }

  render() {
    const language = this.props.i18n.language;
    if (language.includes("ja")) {
      this.selectedLangInline = Language.japanese.code.toLowerCase();
    } else if (language.includes("fr")) {
      this.selectedLangInline = Language.french.code.toLowerCase();
    } else if (language.includes("de")) {
      this.selectedLangInline = Language.deutch.code.toLowerCase();
    } else if (language.includes("zh")) {
      this.selectedLangInline = Language.chinese.code.toLowerCase();
    } else if (language.includes("ko")) {
      this.selectedLangInline = Language.korean.code.toLowerCase();
    } else if (language.includes("pt")) {
      this.selectedLangInline = Language.portuguese.code.toLowerCase();
    } else if (language.includes("es")) {
      this.selectedLangInline = Language.spanish.code.toLowerCase();
    } else if (language.includes("it")) {
      this.selectedLangInline = Language.italian.code.toLowerCase();
    } else if (language.includes("nl")) {
      this.selectedLangInline = Language.dutch.code.toLowerCase();
    }
    const { t } = this.props;
    const selectedLangInline = this.selectedLangInline;
    return (
      <footer className="global-footer section section--padded-one bg--dark-gray-3">
        <nav className="content-container content-container--wide">
          <div className="global-footer__first clearfix">
            <LanguageSelector />
            <div id="global-footer-menu" className="global-footer__menu">
              <ul className="link-list link-list--inline">
                <li>
                  <a
                    href="https://trust.tableau.com"
                    data-value="System Status"
                  >
                    {t("System Status")}
                  </a>
                </li>
                <li>
                  <a
                    href={`//www.tableau.com/${selectedLangInline}/about/blog`}
                    data-value="Blog"
                    data-drupal-link-system-path="node/62360"
                  >
                    {t("Blog")}
                  </a>
                </li>
                <li>
                  <a
                    href={`//www.tableau.com/${selectedLangInline}/developer`}
                    data-value="Developer"
                    data-drupal-link-system-path="node/63292"
                  >
                    {t("Developer")}
                  </a>
                </li>
                <li>
                  <a
                    href={`//www.tableau.com/${selectedLangInline}/academic`}
                    data-value="Academic"
                    data-drupal-link-system-path="node/62668"
                  >
                    {t("Academic")}
                  </a>
                </li>
                <li>
                  <a
                    href={`//www.tableau.com/${selectedLangInline}/about/careers`}
                    data-value="Careers"
                    data-drupal-link-system-path="node/63464"
                  >
                    {t("Careers")}
                  </a>
                </li>
                <li>
                  <a
                    href={`//www.tableau.com/${selectedLangInline}/about/contact`}
                    data-value="Contact Us"
                    data-drupal-link-system-path="node/63501"
                  >
                    {t("Contact Us")}
                  </a>
                </li>
                <li id="global-footer-menu-help">
                  <Link to={HELP_ROUTE}>{t("Help")}</Link>
                </li>
              </ul>
            </div>
            <div
              data-menu-name="footer-secondary"
              id="block-tableau-footer-secondary-menu"
              className="global-footer__links"
            >
              <ul className="link-list link-list--inline">
                <li>
                  <a
                    href={`//www.tableau.com/${selectedLangInline}/legal`}
                    data-value="Legal"
                    data-drupal-link-system-path="node/62372"
                  >
                    {t("Legal")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://www.salesforce.com/company/privacy/"
                    data-value="Privacy"
                    data-drupal-link-system-path="node/62684"
                  >
                    {t("Privacy")}
                  </a>
                </li>
                <li>
                  <a
                    href="https://kb.tableau.com/articles/knowledgebase/uninstalling-tableau-desktop"
                    data-value="Uninstall"
                  >
                    {t("Uninstall")}
                  </a>
                </li>
                <li>
                  <a
                    href="//www.tableau.com/do-not-sell-my-personal-information"
                    data-value="Do Not Sell My Personal Information"
                    data-drupal-link-system-path="node/63602"
                  >
                    {t("Do Not Sell My Personal Information")}
                  </a>
                </li>
                <li>
                  <a
                    id="your-privacy-options-footer"
                    href="https://www.salesforce.com/form/other/privacy-request"
                    data-value="Your Privacy Choices"
                  >
                    {t("Your Privacy Choices")}
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="global-footer__second clearfix">
            <div id="global-footer-social" className="global-footer__social">
              <ul className="link-list link-list--inline ">
                <li>
                  <a
                    data-name="social-interact"
                    data-location="linkedin"
                    href="https://www.linkedin.com/company/tableau-software"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                  >
                    <i
                      className="icon icon--social-linkedin"
                      aria-hidden="true"
                    />
                    <span className="sr-only">LinkedIn</span>
                  </a>
                </li>
                <li>
                  <a
                    data-name="social-interact"
                    data-location="facebook"
                    href="https://www.facebook.com/Tableau/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                  >
                    <i
                      className="icon icon--social-facebook"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Facebook</span>
                  </a>
                </li>
                <li>
                  <a
                    data-name="social-interact"
                    data-location="twitter"
                    href="https://www.twitter.com/tableau"
                    target="_blank"
                    rel="noopener noreferrer"
                    className=""
                  >
                    <i
                      className="icon icon--social-twitter"
                      aria-hidden="true"
                    />
                    <span className="sr-only">Twitter</span>
                  </a>
                </li>
              </ul>
            </div>
            <div id="block-tableau-copyright">
              <div className="">
                <p className="copy-right text--label text--medium-body">
                  © {year}{" "}
                  {t(
                    "Salesforce, Inc."
                  )}
                </p>
              </div>
            </div>
          </div>
        </nav>
      </footer>
    );
  }
}
Footer.propTypes = {
  t: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired
};
export default translate()(Footer);
