import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

class NavItem extends Component {
  render() {
    const { location, to, children } = this.props;
    const isActive = location.pathname === to;

    return (
      <li className={isActive ? 'is-active' : ''}>
        <Link to={to}>{children}</Link>
      </li>
    );
  }
}

NavItem.propTypes = {
  to: PropTypes.string.isRequired,
  location: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired,
};

export default withRouter(NavItem);
