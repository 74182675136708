import { Language } from '../../../common/Constants';
import deConfig from './address-de.json';
import enConfig from './address-en.json';
import frConfig from './address-fr.json';
import jaConfig from './address-ja.json';
import zhConfig from './address-zh.json';
import koConfig from './address-ko.json';
import ptConfig from './address-pt.json';
import esConfig from './address-es.json';
import itConfig from './address-it.json';
import nlConfig from './address-nl.json';

const getAddressConfig = (language) => {
  let result = enConfig;
  switch (language.toUpperCase()) {
    case Language.deutch.code.toUpperCase():
      result = deConfig;
      break;
    case Language.english.code.toUpperCase():
      result = enConfig;
      break;
    case Language.french.code.toUpperCase():
      result = frConfig;
      break;
    case Language.japanese.code.toUpperCase():
      result = jaConfig;
      break;
    case Language.chinese.code.toUpperCase():
      result = zhConfig;
      break;
    case Language.korean.code.toUpperCase():
      result = koConfig;
      break;
    case Language.portuguese.code.toUpperCase():
      result = ptConfig;
      break;
    case Language.spanish.code.toUpperCase():
      result = esConfig;
      break;
    case Language.italian.code.toUpperCase():
      result = itConfig;
      break;
    case Language.dutch.code.toUpperCase():
      result = nlConfig;
      break;
    default:
      break;
  }
  return result;
};

export default getAddressConfig;
