/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:22:03
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2018-02-27 14:36:31
 */
import { Link } from 'react-router-dom';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import {
  CHANGEMYEMAIL_ROUTE,
  CHANGEPASSWORD_ROUTE,
  HOME_ROUTE,
  MANAGEACCOUNT_ROUTE,
  REGISTER_ROUTE,
  RESETPASSWORD_ROUTE,
  SIGN_ME_OUT_ROUTE,
  SIGNOUT_ROUTE,
} from '../../routes';
import { getProfile } from '../../common/SessionManager';

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = { isOpen: false };
  }

  render() {
    const { t } = this.props;
    const profile = getProfile();

    const isRegisterPage = window.location.pathname.toUpperCase() === REGISTER_ROUTE.toUpperCase();
    const isResetPage = window.location.pathname.toUpperCase() === RESETPASSWORD_ROUTE.toUpperCase();

    const toggleHamburger = () => {
      this.setState(prevState => ({
        ...prevState,
        isOpen: !prevState.isOpen,
      }));
    };
    const hide = () => {
      this.setState(prevState => ({
        ...prevState,
        isOpen: false,
      }));
    };

    const signOutUrl = `${SIGN_ME_OUT_ROUTE}?goto=${window.REACT_APP_BASE_URL}${SIGNOUT_ROUTE}`;
    return (
      <header className="global-nav">
        <div className="global-nav__top content-container content-container--wide">
          <div className="relative clearfix">
            <div id="global-nav-logo" className="global-nav__logo">
              <a href="//www.tableau.com/">
                <img src="https://cdns.tblsft.com/sites/all/themes/tabwow/logo.png" alt="Tableau Software" />
              </a>
            </div>
            <div className={`global-nav__mobile-wrapper is-mobile ${this.state.isOpen ? 'is-open' : ''}`}>
              <nav className="global-nav__primary-menu">
                <ul>
                  <li>
                    {profile ? (
                      <Link to={signOutUrl} id="signOutLink" onClick={hide}>
                        {t('Sign Out')}
                      </Link>
                    ) : (
                      <Link to={HOME_ROUTE} id="signInLink" onClick={hide}>
                        {t('Sign In')}
                      </Link>
                    )}
                  </li>
                  {!profile && !isRegisterPage && (
                    <li>
                      <Link to={REGISTER_ROUTE} id="registerLink" onClick={hide}>
                        {t('Register')}
                      </Link>
                    </li>
                  )}
                  {profile && (
                    <li>
                      <Link to={CHANGEPASSWORD_ROUTE} id="changePasswordLink" onClick={hide}>
                        {t('Change Password')}
                      </Link>
                    </li>
                  )}
                  {!profile && !isResetPage && (
                    <li>
                      <Link to={RESETPASSWORD_ROUTE} id="resetPasswordLink" onClick={hide}>
                        {t('Reset Password')}
                      </Link>
                    </li>
                  )}
                  {profile && !profile.profileAdmin && !profile.profileReader && (
                    <li>
                      <Link to={CHANGEMYEMAIL_ROUTE} id="changeEmailLink" onClick={hide}>
                        {t('Change Email')}
                      </Link>
                    </li>
                  )}
                  {profile && (
                    <li>
                      <Link to={MANAGEACCOUNT_ROUTE} id="manageAccountLink" onClick={hide}>
                        {t('Manage Account')}
                      </Link>
                    </li>
                  )}
                </ul>
              </nav>
            </div>
            <div className={`global-nav__hamburger ${this.state.isOpen ? 'open' : ''}`}>
              <div
                id="hamburgerMenu"
                className={`hamburger ${this.state.isOpen ? 'hamburger--open' : ''}`}
                onClick={toggleHamburger}
                onBlur={hide}
                role="button"
                tabIndex={0}
              >
                <span className="hamburger__patty">Menu</span>
              </div>
            </div>
          </div>
        </div>
        <hr className="divider divider--gradient tablet-show" />
      </header>
    );
  }
}
Header.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate()(Header);
