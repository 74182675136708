/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-09 19:18:38
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-20 15:20:12
 */

import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';

import { UserActionTypes, Headers, ErrorCode } from '../../common/Constants';
import { getHeaderFromPayLoad } from '../../common/Util';
import i18n from '../../common/i18n';

const t = i18n.t.bind(i18n);
function getErrorMessage(error) {
  let errorMessage = null;
  if (error.response) {
    const requestId = error.response.headers[Headers.requestId];
    switch (error.response.data.code) {
      case ErrorCode.EMAIL_ALREADY_EXISTS:
        errorMessage = ['This email address is already in use.', 'Please try again.'];
        break;
      case ErrorCode.INVALID_EMAIL:
        errorMessage = ['The Email you supplied was invalid.'];
        break;
      case ErrorCode.INVALID_LAST_NAME:
        errorMessage = ['The Last Name you supplied was invalid.'];
        break;
      case ErrorCode.INVALID_FIRST_NAME:
        errorMessage = ['The First Name you supplied was invalid.'];
        break;
      default:
        errorMessage = ["We're sorry! An unknown error has occurred.", '<br />', `RequestId: ${requestId}`];
        break;
    }
  } else {
    errorMessage = ["We're sorry! An unknown error has occurred."];
  }

  return errorMessage;
}

export default function reducer(
  state = {
    errorMessage: null,
    successMessage: null,
    isFetching: false,
    clientId: null,
    requestId: '',
  },
  action,
) {
  switch (action.type) {
    case `${UserActionTypes.CREATE_USER}_${PENDING}`: {
      return { ...state, isFetching: true, requestId: '' };
    }
    case `${UserActionTypes.CREATE_USER}_${FULFILLED}`: {
      const validGoto = getHeaderFromPayLoad(action.payload, Headers.validGoto).trim();
      const autoGoto = getHeaderFromPayLoad(action.payload, Headers.autoGoto);

      let successMessage = null;
      if (validGoto && autoGoto) {
        successMessage = [
          'Thank you for creating an account.',
          () => t('You may now <a href="{{link}}">login</a>.', {
            link: validGoto,
          }),
        ];
      } else {
        successMessage = [
          'Thank you for registering.',
          'You will receive an email containing a link to confirm this registration.',
          'You must follow the instructions in the email in order to activate your Tableau account.',
        ];
      }

      return {
        ...state,
        isFetching: false,
        successMessage,
        errorMessage: null,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }
    case `${UserActionTypes.CREATE_USER}_${REJECTED}`: {
      const errorMessage = getErrorMessage(action.payload);
      return {
        ...state,
        isFetching: false,
        errorMessage,
        successMessage: null,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }

    default:
      return state;
  }
}
