/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-09 15:06:03
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2018-01-19 09:10:43
 */
import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';

class Input extends Component {
  constructor(props) {
    super(props);
    if (props.value) {
      props.setValue(props.value);
    }
  }

  render() {
    const { id, getValue, setValue } = this.props;
    const changeValue = (event) => {
      setValue(event.currentTarget.value);
    };
    const isActive = getValue() && getValue().length > 0;
    return (
      <div className="form__field form-field has-float-label">
        <label htmlFor={this.props.name} className={`form-field__label ${isActive ? 'is-active' : ''}`}>
          {this.props.title}
        </label>
        <input
          id={id}
          autoFocus={this.props.autoFocus}
          formNoValidate
          maxLength={this.props.maxLength}
          name={this.props.name}
          placeholder={this.props.placeholder}
          type={this.props.type}
          value={getValue()}
          onChange={changeValue}
        />
      </div>
    );
  }
}

Input.propTypes = {
  autoFocus: PropTypes.bool,
  getValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  maxLength: PropTypes.string,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  value: PropTypes.string,
};

Input.defaultProps = {
  autoFocus: null,
  maxLength: '100',
  type: 'text',
  value: '',
};

export default withFormsy(Input);
