/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-09 19:18:38
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-14 12:45:13
 */

import { LOCATION_CHANGE } from 'react-router-redux';
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import { UserActionTypes, Headers } from '../../common/Constants';
import { getHeaderFromPayLoad } from '../../common/Util';

export default function reducer(
  state = {
    error: null,
    success: false,
    isFetching: false,
    requestId: '',
  },
  action,
) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return {};
    }
    case `${UserActionTypes.CHANGE_PASSWORD}_${PENDING}`: {
      return { ...state, isFetching: true, requestId: '' };
    }
    case `${UserActionTypes.CHANGE_PASSWORD}_${FULFILLED}`: {
      return {
        ...state,
        isFetching: false,
        success: true,
        error: null,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }
    case `${UserActionTypes.CHANGE_PASSWORD}_${REJECTED}`: {
      return {
        ...state,
        isFetching: false,
        error: action.payload,
        success: false,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }

    default:
      return state;
  }
}
