import React from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { MessageType } from '../common/Constants';
import HelpActions from '../redux/actions/helpActions';
import HelpForm from '../components/forms/HelpForm';
import LoaderOverlay from '../components/LoaderOverlay';
import Message from '../components/Message';
import { translateArray } from '../common/Util';
import { getHelpErrorMessage } from '../common/MessageUtil';

export const Help = (props) => {
  const {
    t, success, error, isFetching, postHelpForm, requestId,
  } = props;

  const onSubmit = (formData) => {
    postHelpForm(formData);
  };

  const successMessage = 'Thank you for submitting a request to our Tableau Customer Service team, '
    + 'we will reply shortly. Our normal business hours are 5:30am - 7:00pm PST Monday - Friday and we typically '
    + 'respond within the same business day. If you have any additional questions or concerns, please reach out '
    + 'to us at <a href="mailto:customerservice@tableausoftware.com">customerservice@tableausoftware.com</a>.';
  return (
    <section className="section">
      <div className="content-container" id="loading-overlay-content">
        {isFetching && <LoaderOverlay />}
        {error && (
          <Message
            id="errorMessage"
            text={translateArray(getHelpErrorMessage(error))}
            messageType={MessageType.error}
          />
        )}
        {success && <Message id="successMessage" text={t(successMessage)} messageType={MessageType.notice} />}
        {!success && <HelpForm onSubmit={onSubmit} isFetching={isFetching} />}
      </div>
      <input type="hidden" id="requestId" value={requestId} />
    </section>
  );
};

Help.propTypes = {
  t: PropTypes.func.isRequired,
  error: PropTypes.object,
  success: PropTypes.bool,
  isFetching: PropTypes.bool,
  postHelpForm: PropTypes.func.isRequired,
  requestId: PropTypes.string,
};

Help.defaultProps = {
  error: null,
  success: false,
  isFetching: false,
  requestId: '',
};

export const mapStateToProps = state => ({
  error: state.helpReducer.error,
  success: state.helpReducer.success,
  isFetching: state.helpReducer.isFetching,
  requestId: state.helpReducer.requestId,
});

const mapDispatchToProps = dispatch => ({
  postHelpForm: bindActionCreators(HelpActions, dispatch),
});

export default translate()(connect(mapStateToProps, mapDispatchToProps)(Help));
