/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:21:56
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2018-04-10 12:38:22
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Header from './Header';
import Footer from './Footer';
import Navigation from './Navigation';

class Layout extends Component {
  render() {
    return (
      <div>
        <Header />
        <Navigation />
        <hr className="divider divider--gradient" />
        {this.props.children}
        <Footer />
      </div>
    );
  }
}
Layout.propTypes = {
  children: PropTypes.any.isRequired,
};

export default Layout;
