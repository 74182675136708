/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 08:19:37
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2017-11-20 11:30:44
 */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import QuickLinks from '../components/QuickLinks';
import Message from '../components/Message';
import { MessageType } from '../common/Constants';

class SignOut extends Component {
  render() {
    const { t } = this.props;
    document.title = 'Tableau ';
    return (
      <div className="content-container">
        <section className="section">
          <div className="container-fluid">
            <Message id="signOutSuccess" text={t('You are now signed out.')} messageType={MessageType.notice} />
            <QuickLinks />
          </div>
        </section>
      </div>
    );
  }
}
SignOut.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate()(SignOut);
