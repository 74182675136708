/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-09 19:18:38
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-14 12:47:16
 */

import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import { LOCATION_CHANGE } from 'react-router-redux';
import { UserActionTypes, Headers, ErrorCode } from '../../common/Constants';
import * as UserModel from '../../models/UserModel';
import { getHeaderFromPayLoad } from '../../common/Util';

function getErrorMessage(error) {
  let errorMessage = null;
  if (error.response) {
    const requestId = error.response.headers[Headers.requestId];
    switch (error.response.data.code) {
      case ErrorCode.UNAUTHORIZED:
      case ErrorCode.FORBIDDEN:
        errorMessage = ["We're sorry. You are not authorized to access the requested page or URL."];
        break;
      case ErrorCode.INVALID_EMAIL:
        errorMessage = ['The Email you supplied was invalid.'];
        break;
      case ErrorCode.INVALID_WILDCARD:
        errorMessage = ['Wildcards filters are not supported for this field.'];
        break;
      case ErrorCode.INVALID_WILDCARD_EMAIL:
        errorMessage = ['Prefixed wildcard must have at least 3 chars.'];
        break;
      case ErrorCode.AUTH0_TIMEOUT_ERROR:
        errorMessage = ['The query exceeded the timeout. Please try refining your search criteria.'];
        break;
      case ErrorCode.AUTH0_CANNOT_PARSE_ERROR:
        errorMessage = ['Invalid query.  Auth0 could not parse the query.'];
        break;
      default:
        errorMessage = ["We're sorry! An unknown error has occurred.", '<br />', `RequestId: ${requestId}`];
        break;
    }
  } else {
    errorMessage = ["We're sorry! An unknown error has occurred."];
  }

  return errorMessage;
}

const isOnlineUser = (loginHistory) => {
  const onlineUser = Object.keys(loginHistory).find(client => client.toLowerCase().includes("online"));
  return onlineUser ? true : false;
}

export default function reducer(
  state = {
    errorMessage: null,
    success: null,
    isFetching: false,
    searchResults: null,
    userModel: null,
    notFound: false,
    requestId: '',
    onlineUser: false,
  },
  action,
) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return {};
    }
    case `${UserActionTypes.SEARCH_USERS}_${PENDING}`: {
      return {
        ...state,
        isFetching: true,
        searchResults: null,
        requestId: '',
      };
    }
    case `${UserActionTypes.SEARCH_USERS}_${FULFILLED}`: {
      const currentUsers = UserModel.mapUsers(action.payload.data.currentUsers);
      const pastUsers = UserModel.mapUsers(action.payload.data.pastUsers);
      let notFound = false;
      let success = true;
      let userModel = null;
      let onlineUser = false;
      // userModel is used in AccountAdmin/UserDetailView only.
      // if search returns only one user in currentUsers && pastUsers,
      // that user will be assigned as useModel and it will be automatically displayed in AccountAdmin/UserDetailView.
      if (currentUsers.length === 0 && pastUsers.length === 0) {
        notFound = true;
        success = false;
      } else if (currentUsers.length === 0 && pastUsers.length === 1) {
        userModel = pastUsers[0];
      } else {
        userModel = currentUsers[0];
        onlineUser = userModel && userModel.loginHistory ? isOnlineUser(userModel.loginHistory) : false;
      }
      return {
        ...state,
        isFetching: false,
        success,
        currentUsers,
        pastUsers,
        emailSearch: action.meta.emailSearch,
        uidSearch: action.meta.uidSearch,
        invalidWildCardSearch: action.meta.invalidWildCardSearch,
        cannotSearchEmailChangeHistory: action.meta.cannotSearchEmailChangeHistory,
        notFound,
        errorMessage: null,
        userModel,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
        onlineUser,
      };
    }
    case `${UserActionTypes.SEARCH_USERS}_${REJECTED}`: {
      const errorMessage = getErrorMessage(action.payload);
      return {
        ...state,
        isFetching: false,
        errorMessage,
        success: null,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }

    default:
      return state;
  }
}
