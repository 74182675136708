/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:19:33
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-29 08:14:58
 */

// https://reactjs.org/docs/javascript-environment-requirements.html
import 'core-js';

import React from 'react';
import ReactDOM from 'react-dom';
import { I18nextProvider } from 'react-i18next';
import { Provider } from 'react-redux';

import App from './App';
import { unregister } from './registerServiceWorker';
import i18n from './common/i18n';
import store from './redux/store';
import { setSessionId } from './common/SessionManager';
import Console from './common/Console';
import { logError, logInfo } from './common/Logger';

try {
  // Check if localStorage is disabled.
  // eslint-disable-next-line
  const localStorage = window.localStorage;
  /*
      IE doesn't share local storage values across tabs without this line of code.
      They won't fix the issue and it was only fixed in EDGE. This breaks the auth model when using multiple tabs.
      Adding an empty on storage handler fixes the issue.
  */
  // eslint-disable-next-line
  window.onstorage = function(e) {};
  setSessionId();
} catch (ex) {
  Console.log(ex.code, ex.message);
  logInfo({ code: ex.code, message: ex.message, stack: ex.stack }, true);
  window.location.href = `${window.REACT_APP_BASE_URL}/cookies.html`;
}

try {
  ReactDOM.render(
    <I18nextProvider i18n={i18n}>
      <Provider store={store}>
        <App />
      </Provider>
    </I18nextProvider>,
    document.getElementById('root'),
  );
  unregister();
} catch (ex) {
  Console.log(ex.code, ex.message);
  logError({ code: ex.code, message: ex.message, stack: ex.stack }, true);
  window.location.href = `${window.REACT_APP_BASE_URL}/error.html`;
}
