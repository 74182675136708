/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:21:17
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2018-01-18 15:06:06
 */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import Message from '../components/Message';
import QuickLinks from '../components/QuickLinks';
import { MessageType } from '../common/Constants';
import { getProfile } from '../common/SessionManager';

class Home extends Component {
  render() {
    const { t } = this.props;
    document.title = 'Tableau';
    const profile = getProfile();
    const email = profile.email || '';

    return (
      <div className="content-container">
        <section className="section">
          <div className="container-fluid">
            <Message
              id="signInSuccess"
              text={`${t('You are now signed in.')} ${email}`}
              messageType={MessageType.notice}
            />
            <QuickLinks />
          </div>
        </section>
      </div>
    );
  }
}
Home.propTypes = {
  t: PropTypes.func.isRequired,
};
export default translate()(Home);
