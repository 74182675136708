import { translate } from 'react-i18next';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import getCountries from '../../common/getCountries';
import * as UserModel from '../../models/UserModel';
import getAddressConfig from './Data/AddressConfig';
import marketingRegion1Countries from './Data/marketing-region-1-countries.json';
import Checkbox from './elements/Checkbox';
import ValidationCheckbox from './elements/ValidationCheckbox';
import ValidationSelect from './elements/ValidationSelect';

export const formTestId = 'AdditionalInfoForm-form';
export const headingTestId = 'AdditionalInfoForm-heading';
export const submitButtonTestId = 'AdditionalInfoForm-submitButton';

export class AdditionalInfoForm extends Component {
  constructor(props) {
    super(props);
    this.state = {
      config: getAddressConfig(this.props.i18n.language),
      region1CountrySelected: false,
      submitDisabled: false,
    };
    this.setStateFromLanguage = this.setStateFromLanguage.bind(this);
  }

  componentDidMount() {
    this.props.i18n.on('languageChanged', this.setStateFromLanguage);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.setState({ submitDisabled: false });
    }
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.setStateFromLanguage);
  }

  setStateFromLanguage(language) {
    this.setState({ config: getAddressConfig(language) });
  }

  onValidSubmit = (formData) => {
    this.setState({ submitDisabled: true });
    this.props.onSubmit(formData);
  };

  onInvalidSubmit = () => {
    this.setState({ submitDisabled: false });
  };

  // ValidationSelect component returns `event.value` from onChange, rather than `event`.
  onCountryChange = (eventValue) => {
    const selectedCountryIso = eventValue;
    // If a country is Region 1, users are opted out of marketing communications by default.
    // If a country is not Region 1, users are opted in to marketing communications by default.
    const countryIsRegion1 = selectedCountryIso !== '' && marketingRegion1Countries[selectedCountryIso] === true;
    this.setState({ region1CountrySelected: countryIsRegion1 });
  };

  mapInputs = inputs => UserModel.mapInputsFromAdditionalInfoEdit(this.props.userModel, inputs);

  render() {
    const { t, userModel } = this.props;
    const { config, region1CountrySelected, submitDisabled } = this.state;

    return (
      <div id="form" className="AdditionalInfoForm clearfix">
        <h1 className="title heading--h2" data-test-id={headingTestId}>
          {t('Tableau Public needs more information')}
        </h1>
        <p className="country-description">{t('Select your country or region.')}</p>
        <Formsy
          className="form--full-width"
          data-test-id={formTestId}
          onValidSubmit={this.onValidSubmit}
          onInvalidSubmit={this.onInvalidSubmit}
          mapping={this.mapInputs}
          noValidate
        >
          <div className="clearfix">
            <div className="grid--4">
              <ValidationSelect
                autoFocus
                id="country"
                name="country"
                options={getCountries(config)}
                onChange={this.onCountryChange}
                required="isEmptyString"
                title={config.label}
                translateContents={false}
                validationErrors={{
                  isEmptyString: t('You must select a country or region.'),
                }}
                value={userModel.country || ''}
              />
            </div>
          </div>
          <div className="clearfix">
            <div className="grid--6">
              <ValidationCheckbox
                id="codeOfConductAcceptance"
                name="codeOfConductAcceptance"
                required
                title={(
                  <span
                    dangerouslySetInnerHTML={{
                      __html: t(
                        'I agree to the <a target="_blank" href="https://www.tableau.com/en-us/tos"><b>Terms</b></a>, <a target="_blank" href="https://public.tableau.com/app/data-policy"><b>Data Policy</b></a>, and <a target="_blank" href="https://mkt.tableau.com/legal/tableau_community_conduct.pdf"><b>Community Code</b></a>. <br>By registering you confirm that you agree to the processing of your personal data as described in the <a target="_blank" href="https://www.salesforce.com/company/privacy/full_privacy/"><b>Salesforce Privacy Statement</b></a>.',
                      ),
                    }}
                  />
                )}
                validations="isTrue"
                validationError={t('You must agree before you can continue.')}
              />
            </div>
          </div>
          <div className={`clearfix ${!region1CountrySelected && 'hide'}`}>
            <div className="grid--6">
              <Checkbox
                checked={!region1CountrySelected}
                id="marketingEmailOptIn"
                label={t(
                  'Yes, I would like to receive marketing communications regarding Salesforce products, services, and events. I can unsubscribe at any time.',
                )}
                name="marketingEmailOptIn"
                value="true"
              />
            </div>
          </div>
          <div className="form-buttons">
            <button
              className="cta cta--secondary"
              data-test-id={submitButtonTestId}
              disabled={submitDisabled}
              id="submitAdditionalInfoButton"
              type="submit"
            >
              {t('Complete Sign-In')}
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}

AdditionalInfoForm.propTypes = {
  i18n: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  userModel: PropTypes.object.isRequired,
};

export default translate()(AdditionalInfoForm);
