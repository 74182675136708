/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-09 15:06:05
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-29 08:16:29
 */
import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

class Select extends Component {
  constructor(props) {
    super(props);
    if (props.value) {
      props.setValue(props.value);
      if (props.onChange) {
        props.onChange(props.value);
      }
    }
  }

  isValidSelection = (options, value) => {
    let result = true;
    if (!value || value === '' || !options.find(element => element.value === value)) {
      result = false;
    }
    return result;
  };

  render() {
    const {
      id, title, options, t, getValue, setValue, name, onChange, translateContents,
    } = this.props;
    const isValidSelection = this.isValidSelection(options, getValue());

    const handleChange = (event) => {
      setValue(event.currentTarget.value);
      if (onChange) {
        onChange(event.currentTarget.value);
      }
    };
    return (
      <div className="form-field form__field has-float-label">
        <label className={`form-field__label ${isValidSelection ? 'is-active' : ''}`} htmlFor={id}>
          {translateContents ? t(title) : title}
        </label>
        <select
          className={isValidSelection ? '' : 'is-empty'}
          id={id}
          name={name}
          onChange={handleChange}
          size="1"
          value={getValue()}
        >
          {options.map(option => (
            <option key={option.id} value={option.value}>
              {translateContents ? t(option.label) : option.label}
            </option>
          ))}
        </select>
      </div>
    );
  }
}

Select.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  title: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  value: PropTypes.string,
  onChange: PropTypes.func,
  translateContents: PropTypes.bool,
};

Select.defaultProps = {
  value: '',
  onChange: null,
  translateContents: true,
};
export default translate()(withFormsy(Select));
export { Select as PureSelect }; // for unit testing
