import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';
import Message from '../Message';
import { MessageType } from '../../common/Constants';
import UserResultTable from './UserResultTable';

const UserResultView = (props) => {
  const {
    t, users, emailChangeHistory, invalidWildCardSearch, cannotSearchEmailChangeHistory,
  } = props;
  const NoSearchFoundMessage = () => (
    <Message id="zeroResultMessage" text={t('Search returned no results.')} messageType={MessageType.notice} />
  );
  const InvalidWildCardSearchMessage = () => (
    <div id="invalidWildCardSearchMessage" className="email-change-history">
      {`${t('Displaying search results for')} "*${invalidWildCardSearch.split('*')[1]}"`}
    </div>
  );

  const UsersListMessage = data => (
    <div className="heading-highlight-gray text--benton-book margin-top-three margin-bottom-one">
      {data.current
        ? `${t('Current user(s) with this email')}`
        : `${t('Users that were previously associated with this email')}`}
    </div>
  );

  return (
    <div>
      {!emailChangeHistory && <UsersListMessage current />}
      {emailChangeHistory && <UsersListMessage current={false} />}
      {emailChangeHistory && cannotSearchEmailChangeHistory && (
        <Message
          id="cannotSearchEmailChangeHistory"
          text={t(
            "Couldn't perform search for this section.  Search word must include a domain name.  e.g., *@tableau.com",
          )}
          messageType={MessageType.notice}
        />
      )}
      {!cannotSearchEmailChangeHistory && invalidWildCardSearch.length > 0 && <InvalidWildCardSearchMessage />}
      {!cannotSearchEmailChangeHistory && users.length === 0 && <NoSearchFoundMessage />}
      {users.length > 0 && <UserResultTable users={users} />}
    </div>
  );
};

UserResultView.propTypes = {
  t: PropTypes.func.isRequired,
  users: PropTypes.array.isRequired,
  invalidWildCardSearch: PropTypes.string.isRequired,
  emailChangeHistory: PropTypes.bool.isRequired,
  cannotSearchEmailChangeHistory: PropTypes.bool.isRequired,
};

export default translate()(UserResultView);
