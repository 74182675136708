/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:21:51
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-28 15:29:33
 */
import React from 'react';
import PropTypes from 'prop-types';
import Console from '../common/Console';
import ErrorPage from '../pages/ErrorPage';

class ErrorBoundry extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch(error, info) {
    this.setState({ hasError: true, error, info });
  }

  render() {
    if (this.state.hasError) {
      Console.log(this.state.error, this.state.error.stack, this.state.info);
      if (window.REACT_APP_DEBUG === 'true') {
        return <ErrorPage code="500" error={this.state.error.message} errorStack={this.state.error.stack} />;
      }
      return <ErrorPage code="500" />;
    }
    return this.props.children;
  }
}

ErrorBoundry.propTypes = {
  children: PropTypes.element.isRequired,
};

export default ErrorBoundry;
