import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getProfile } from '../common/SessionManager';
import { TokenTypes, MessageType } from '../common/Constants';
import * as TokenActions from '../redux/actions/tokenActions';
import * as Util from '../common/Util';
import LoaderOverlay from '../components/LoaderOverlay';
import Message from '../components/Message';
import SubmitEmailForm from '../components/forms/SubmitEmailForm';

export class ResendToken extends Component {
  constructor(props) {
    super(props);
    const profile = getProfile();
    this.state = {
      showEmail: profile && profile.showEmail,
    };
  }

  render() {
    const {
      clientId,
      generateLocally,
      emailTo,
      errorMessage,
      externalError,
      i18n,
      isFetching,
      postToken,
      sent,
      successMessage,
      tokenType,
      t,
      requestId,
      relayState,
      tokenActionUrl
    } = this.props;


    let title = '';
    let subTitle = null;
    let buttonText = '';
    let buttonId = '';
    switch (tokenType) {
      case TokenTypes.RESEND_ACTIVATION:
        title = 'Resend Activation Email';
        buttonText = 'Resend Activation Email';
        buttonId = 'resendActivationButton';
        break;
      case TokenTypes.RESET_PASSWORD:
        title = 'Reset Password';
        subTitle = 'Please provide your email address to reset your password.';
        buttonText = 'Send Password Reset Email';
        buttonId = 'resetPasswordButton';
        break;
      default:
        throw Error(`Invalid token type (${tokenType}) passed into ResendToken`);
    }

    const onSubmit = (formData) => {
      postToken(tokenType, formData.email, formData.viewEmail, i18n.language, clientId, relayState);
    };
    return (
      <section className="section">
        <div className="content-container" id="loading-overlay-content">
          <div>
            {isFetching && <LoaderOverlay />}
            {sent && !generateLocally && !this.state.showEmail && (
              <Message id="sentMessage" text={Util.translateArray(successMessage)} messageType={MessageType.notice} />
            )}
            {sent && !generateLocally && this.state.showEmail && (
              <Message id="sentMessage"
                 text={
                     "We've sent the user <span style='color: blue'>" + emailTo+ "</span>" +
                     " an email with a link to finish resetting their password.<br />" +
                     "Can't find the email? Ask the user to check their spam folder."
                 }
                 messageType={MessageType.notice} />
            )}
            {errorMessage && (
              <Message id="errorMessage" text={Util.translateArray(errorMessage)} messageType={MessageType.error} />
            )}
            {externalError && !errorMessage && !sent && (
              <Message id="errorMessage" text={externalError} messageType={MessageType.error} />
            )}
            { generateLocally && (
              <Message id="localResetPassword"
                text={
                   'This is the generated token URL: ' +
                   '<a href="' + tokenActionUrl + '">' + tokenActionUrl + '</a>.<br />' +
                   'Please provide this URL to the user: <span style="color: blue">' + emailTo+ '</span>'
                }
                messageType={MessageType.notice}
              />
            )}
          </div>
          {!sent && (
            <SubmitEmailForm
              onSubmit={onSubmit}
              isFetching={isFetching}
              title={t(title)}
              subTitle={t(subTitle)}
              buttonText={t(buttonText)}
              buttonId={buttonId}
              showEmail={this.state.showEmail}
            />
          )}
        </div>
        <input type="hidden" id="requestId" value={requestId} />
      </section>
    );
  }
}

ResendToken.propTypes = {
  clientId: PropTypes.string,
  generateLocally: PropTypes.bool,
  emailTo: PropTypes.string,
  errorMessage: PropTypes.array,
  externalError: PropTypes.string,
  i18n: PropTypes.object.isRequired,
  isFetching: PropTypes.bool,
  postToken: PropTypes.func.isRequired,
  sent: PropTypes.bool,
  successMessage: PropTypes.array,
  tokenType: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
  requestId: PropTypes.string,
  relayState: PropTypes.string,
  tokenActionUrl: PropTypes.string
};

ResendToken.defaultProps = {
  clientId: null,
  generateLocally: false,
  emailTo: null,
  errorMessage: null,
  externalError: null,
  isFetching: false,
  sent: false,
  successMessage: null,
  requestId: '',
  relayState: null,
  tokenActionUrl: ''
};

export const mapStateToProps = state => ({
  errorMessage: state.resendReducer.errorMessage,
  successMessage: state.resendReducer.successMessage,
  isFetching: state.resendReducer.isFetching,
  sent: state.resendReducer.sent,
  emailTo: state.resendReducer.emailTo,
  clientId: state.locationReducer.clientId,
  requestId: state.resendReducer.requestId,
  relayState: state.locationReducer.relayState,
  tokenActionUrl: state.resendReducer.tokenActionUrl,
  generateLocally: state.resendReducer.generateLocally
});

const mapDispatchToProps = dispatch => ({
  postToken: bindActionCreators(TokenActions.postToken, dispatch),
});

export default translate()(connect(mapStateToProps, mapDispatchToProps)(ResendToken));