/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:20:00
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-28 15:28:36
 */

import queryString from 'querystring';
import axios from 'axios';

import { FULFILLED } from 'redux-promise-middleware';

import {
  UserActionTypes, EndPoints, Headers, LocalStorageKeys,
} from '../../common/Constants';
import { getHeaderFromPayLoad } from '../../common/Util';
import { logInfo, logPromiseError } from '../../common/Logger';
import { getHeaders } from '../../common/SessionManager';
import LocalStorage from '../../common/LocalStorage';

const usersUrl = `${window.REACT_APP_API_URL}${EndPoints.Users}`;

export function searchUsers(userInfo) {
  let emailSearch = false;
  let uidSearch = false;
  let invalidWildCardSearch = '';
  let cannotSearchEmailChangeHistory = false;
  if (userInfo.uid && userInfo.uid.length > 0) {
    uidSearch = true;
  } else if (userInfo.uid !== undefined && userInfo.uid.length === 0 && userInfo.email.length > 0) {
    // in case email field exists, but is missing a domain name (e.g. 'johnny*' 'johnny@') => don't perform email change history search.
    // Auth0's search in app_metadata doesn't work well. (when searching "johnny@' it will return )
    if (userInfo.email.includes('*') && userInfo.email.split('*')[1].length === 0) {
      userInfo.emailChangeHistory = false;
      cannotSearchEmailChangeHistory = true;
    } else if (
      !userInfo.email.includes('@')
      || (userInfo.email.includes('@') && userInfo.email.split('@')[1].length === 0)
    ) {
      userInfo.emailChangeHistory = false;
    } else {
      userInfo.emailChangeHistory = true;
    }
    emailSearch = true;
  }
  if (userInfo.email.includes('*') && userInfo.email.split('*')[0].length !== 0) {
    invalidWildCardSearch = userInfo.email;
  }
  return (dispatch) => {
    const qs = queryString.stringify(userInfo);
    getHeaders(null).then((headers) => {
      dispatch({
        type: UserActionTypes.SEARCH_USERS,
        meta: {
          emailSearch,
          uidSearch,
          invalidWildCardSearch,
          cannotSearchEmailChangeHistory,
        },
        payload: axios.get(`${usersUrl}?${qs}`, { headers }),
      }).catch((error) => {
        logPromiseError('userActions.searchUsers()', error);
      });
    });
  };
}

export function getUser(userId) {
  return (dispatch) => {
    getHeaders(null).then((headers) => {
      dispatch({
        type: UserActionTypes.GET_USER,
        payload: axios.get(`${usersUrl}/${userId}`, { headers }),
      }).catch((error) => {
        logPromiseError('userActions.getUser()', error);
      });
    });
  };
}

export function updateUser(userId, user, language, goto) {
  return (dispatch) => {
    getHeaders(language, null, goto).then((headers) => {
      dispatch({
        type: UserActionTypes.UPDATE_USER,
        payload: axios.post(`${usersUrl}/${userId}`, user, { headers }),
      }).catch((error) => {
        logPromiseError('userActions.updateUser()', error);
      });
    });
  };
}

export function createUser(userInfo, clientId, language, relayState) {
  return (dispatch) => {
    getHeaders(language, clientId, null, relayState).then((headers) => {
      dispatch({
        type: UserActionTypes.CREATE_USER,
        payload: axios.post(usersUrl, userInfo, { headers }),
      })
        .then((response) => {
          if (response.action.payload) {
            const validGoto = getHeaderFromPayLoad(response.action.payload, Headers.validGoto).trim();
            const autoGoto = getHeaderFromPayLoad(response.action.payload, Headers.autoGoto);
            if (validGoto && autoGoto === 'true') {
              window.location = validGoto;
            }
          }
        })
        .catch((error) => {
          logPromiseError('userActions.createUser()', error);
        });
    });
  };
}

export function createHoneyPot() {
  logInfo({
    eventType: 'honeyPot',
    message: 'Register Bot: Honeypot field was not empty.',
  });
  return (dispatch) => {
    dispatch({
      type: `${UserActionTypes.CREATE_USER}_${FULFILLED}`,
    });
  };
}
export function changePassword(userId, oldPassword, newPassword, language) {
  return (dispatch) => {
    getHeaders(language).then((headers) => {
      LocalStorage.setItem(LocalStorageKeys.passwordChangeStatus, true);
      dispatch({
        type: UserActionTypes.CHANGE_PASSWORD,
        payload: axios.post(`${usersUrl}/${userId}/password`, { oldPassword, newPassword }, { headers }),
      }).catch((error) => {
        logPromiseError('userActions.changePassword()', error);
        LocalStorage.setItem(LocalStorageKeys.passwordChangeStatus, false);
      });
    });
  };
}
