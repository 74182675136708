/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:21:37
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-07-09 19:19:08
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

import { HOME_ROUTE, SIGNOUT_ROUTE, UNAUTHORIZED_ROUTE, CHANGEMYEMAIL_ROUTE } from '../routes';
import Auth from '../common/Auth';
import { getProfile, isAuthenticated } from '../common/SessionManager';
import History from '../common/History';
import Loader from './Loader';
import LocalStorage from "../common/LocalStorage";
import { LocalStorageKeys } from "../common/Constants";

const auth = new Auth();
let selectedLanguage = null;
let goTo = null;

class RequireLogin extends Component {
  constructor(props) {
    super(props);
    this.state = { authenticated: false };
    selectedLanguage = this.props.i18n.language;
    goTo = props.location.pathname;
    LocalStorage.setItem(LocalStorageKeys.publicSession, false);
    // If the user is signing in from the logout page, don't take them back there.
    // Take them to the home page after login.
    if (goTo === SIGNOUT_ROUTE) {
      goTo = HOME_ROUTE;
    }
  }

  componentWillMount() {
    this.authenticate();
  }

  componentDidMount() {
    const profileLanguage = this.profile && this.profile.language;
    if (profileLanguage) this.props.i18n.changeLanguage(profileLanguage);
  }

  componentWillReceiveProps() {
    this.authenticate();
  }

  authenticate = () => {
    isAuthenticated()
      .then(() => {
        this.setState({ authenticated: true });
      })
      .catch(() => {
        this.setState({ authenticated: false });
        auth.login(selectedLanguage, goTo);
      });
  };

  render() {
    const { profileAdmin, profileReader, changeMyEmailRoute } = this.props;
    this.profile = getProfile();
    const profile = this.profile;
    if (this.state.authenticated && profile) {
      if (profileAdmin && profile.profileAdmin) {
        return this.props.children;
      }
      if (profileReader && profile.profileReader) {
        return this.props.children;
      }
      if (changeMyEmailRoute && profile) {
        History.push(CHANGEMYEMAIL_ROUTE);
      }
      if (profile && !profileAdmin && !profileReader) {
        return this.props.children;
      }
      History.push(UNAUTHORIZED_ROUTE);
    }
    return <Loader />;
  }
}

RequireLogin.propTypes = {
  children: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  profileAdmin: PropTypes.bool,
  profileReader: PropTypes.bool,
  changeMyEmailRoute: PropTypes.bool,
};

RequireLogin.defaultProps = {
  profileReader: false,
  profileAdmin: false,
  changeMyEmailRoute: false,
};

export default translate()(RequireLogin);
