/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-23 12:22:43
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-28 15:28:18
 */

import axios from 'axios';

import { HelpActionTypes, EndPoints } from '../../common/Constants';
import { logPromiseError } from '../../common/Logger';

const helpUrl = `${window.REACT_APP_API_URL}${EndPoints.Help}`;
export default function postHelpForm(formData) {
  return (dispatch) => {
    dispatch({
      type: HelpActionTypes.SUBMIT,
      payload: axios.post(helpUrl, formData),
    }).catch((error) => {
      logPromiseError('helpActions.postHelpForm()', error);
    });
  };
}
