export const ActivationTitle = {
  MARKETING_TITLE_1: 'Marketing Activation',
  MARKETING_TITLE_2: 'Marketing Activation Long Title',
  TABLEAU_CLOUD_ACTIVATION: 'Create Your Account',
};

export const ActivationDetails = {
  MARKETING_DETAILS_1: 'string activation details - plaid team testing',
  MARKETING_DETAILS_2:
    '<h4 class="[modifier-class] link breadcrumb-links__link">Marketing Details!</h4><p class="search-result__summary">This is <b>Marketing Activation</b> Page, &#128025;</p>',
};
