import { translate } from 'react-i18next';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import ValidationInput from './elements/ValidationInput';
import Input from './elements/Input';
import ValidationTextArea from './elements/ValidationTextArea';

class HelpForm extends Component {
  constructor(props) {
    super(props);
    this.state = { submitDisabled: false };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.setState({ submitDisabled: false });
    }
  }

  onValidSubmit = (formData) => {
    this.setState({ submitDisabled: true });
    this.props.onSubmit(formData);
  };

  onInvalidSubmit = () => {
    this.setState({ submitDisabled: false });
  };

  mapInputs = inputs => ({
    name: inputs.name,
    email: inputs.email,
    company: inputs.company,
    description: inputs.description,
  });

  render() {
    const { t } = this.props;

    return (
      <div id="form" className="clearfix">
        <h1 className="title">{t('Having a problem with your account?')}</h1>
        <p>
          {t(
            'If you are having difficulty signing in, you can reset your password using the link above.  '
              + 'Otherwise, please use the form below to ask us a question or tell us about your problem, '
              + 'and we will respond as soon as we can.',
          )}
        </p>
        <Formsy
          className="form--full-width"
          onValidSubmit={this.onValidSubmit}
          onInvalidSubmit={this.onInvalidSubmit}
          mapping={this.mapInputs}
          noValidate
        >
          <div className="clearfix">
            <div className="grid--4">
              <ValidationInput
                autoFocus
                id="name"
                maxLength="40"
                name="name"
                placeholder={t('Name')}
                type="text"
                required
                value=""
                validationErrors={{
                  isDefaultRequiredValue: t('Please provide your name.'),
                }}
                title={t('Name')}
              />
              <ValidationInput
                id="email"
                maxLength="80"
                name="email"
                placeholder={t('Email')}
                type="email"
                required
                value=""
                validations="isEmail"
                validationErrors={{
                  isDefaultRequiredValue: t('This field is required.'),
                }}
                validationError={t('Please enter a valid email address.')}
                title={t('Email')}
              />
              <Input
                id="company"
                maxLength="100"
                name="company"
                placeholder={t('Company (Optional)')}
                type="text"
                title={t('Company')}
                value=""
              />
            </div>
          </div>
          <div className="clearfix">
            <div className="grid--8">
              <ValidationTextArea
                id="description"
                maxLength="1000"
                name="description"
                required
                value=""
                validationErrors={{
                  isDefaultRequiredValue: t('Please provide a question or issue before submitting.'),
                }}
                title={t('Question or Issue')}
              />
            </div>
          </div>
          <div className="form-buttons">
            <button type="submit" className="cta cta--secondary" id="helpButton" disabled={this.state.submitDisabled}>
              {t('Submit')}
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}

HelpForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};
export default translate()(HelpForm);
