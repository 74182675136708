/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:22:00
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-26 12:22:10
 */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import { Language } from '../../common/Constants';

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-access-state-in-setstate */

class LanguageSelector extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lang: this.getLanguageText(this.props.i18n.language),
      show: false,
    };
    this.touchsupport = 'ontouchstart' in window || navigator.maxTouchPoints > 0 || navigator.msMaxTouchPoints > 0;
    this.setStateFromLanguage = this.setStateFromLanguage.bind(this);
    this.toggleShow = this.toggleShow.bind(this);
    this.showMenu = this.showMenu.bind(this);
    this.hideMenu = this.hideMenu.bind(this);
  }

  componentDidMount() {
    this.props.i18n.on('languageChanged', this.setStateFromLanguage);
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.setStateFromLanguage);
  }

  setStateFromLanguage(language) {
    this.setState({ lang: this.getLanguageText(language) });
  }

  getLanguageText = (language) => {
    let languageText = '';
    if (language.includes('en')) {
      languageText = Language.english.label;
    } else if (language.includes('fr')) {
      languageText = Language.french.label;
    } else if (language.includes('de')) {
      languageText = Language.deutch.label;
    } else if (language.includes('ja')) {
      languageText = Language.japanese.label;
    } else if (language.includes('zh')) {
      languageText = Language.chinese.label;
    } else if (language.includes('ko')) {
      languageText = Language.korean.label;
    } else if (language.includes('pt')) {
      languageText = Language.portuguese.label;
    } else if (language.includes('es')) {
      languageText = Language.spanish.label;
    } else if (language.includes('it')) {
      languageText = Language.italian.label;
    } else if (language.includes('nl')) {
      languageText = Language.dutch.label;
    } else {
      languageText = Language.english.label;
    }
    return languageText;
  };

  changeLanguage = lng => () => {
    this.props.i18n.changeLanguage(lng);
  };

  toggleShow() {
    this.setState(prevState => ({
      ...prevState,
      show: !prevState.show,
    }));
  }

  showMenu() {
    if (!this.touchsupport) {
      this.setState({ show: true });
    }
  }

  hideMenu() {
    if (!this.touchsupport) {
      this.setState({ show: false });
    }
  }

  render() {
    return (
      <div
        className="global-footer__language"
        role="presentation"
        onMouseEnter={this.showMenu}
        onMouseLeave={this.hideMenu}
        onClick={this.toggleShow}
      >
        <span className="selected">{this.state.lang}</span>
        {this.state.show && (
          <ul className="global-footer__language__options plain-list">
            <li className="en first">
              <a
                role="menuitem"
                tabIndex="-1"
                className="languageLink first"
                onClick={this.changeLanguage(Language.english.code)}
              >
                {Language.english.label}
              </a>
            </li>
            <li className="zh">
              <a
                role="menuitem"
                tabIndex="-1"
                className="languageLink"
                onClick={this.changeLanguage(Language.chinese.code)}
              >
                {Language.chinese.label}
              </a>
            </li>
            <li className="fr">
              <a
                role="menuitem"
                tabIndex="-1"
                className="languageLink"
                onClick={this.changeLanguage(Language.french.code)}
              >
                {Language.french.label}
              </a>
            </li>
            <li className="de">
              <a
                role="menuitem"
                tabIndex="-1"
                className="languageLink"
                onClick={this.changeLanguage(Language.deutch.code)}
              >
                {Language.deutch.label}
              </a>
            </li>
            <li className="ja">
              <a
                role="menuitem"
                tabIndex="-1"
                className="languageLink"
                onClick={this.changeLanguage(Language.japanese.code)}
              >
                {Language.japanese.label}
              </a>
            </li>
            <li className="ko">
              <a
                role="menuitem"
                tabIndex="-1"
                className="languageLink"
                onClick={this.changeLanguage(Language.korean.code)}
              >
                {Language.korean.label}
              </a>
            </li>
            <li className="pt">
              <a
                role="menuitem"
                tabIndex="-1"
                className="languageLink"
                onClick={this.changeLanguage(Language.portuguese.code)}
              >
                {Language.portuguese.label}
              </a>
            </li>
            <li className="es">
              <a
                role="menuitem"
                tabIndex="-1"
                className="languageLink"
                onClick={this.changeLanguage(Language.spanish.code)}
              >
                {Language.spanish.label}
              </a>
            </li>
            <li className="it">
              <a
                role="menuitem"
                tabIndex="-1"
                className="languageLink"
                onClick={this.changeLanguage(Language.italian.code)}
              >
                {Language.italian.label}
              </a>
            </li>
            <li className="nl last">
              <a
                role="menuitem"
                tabIndex="-1"
                className="languageLink last"
                onClick={this.changeLanguage(Language.dutch.code)}
              >
                {Language.dutch.label}
              </a>
            </li>
          </ul>
        )}
      </div>
    );
  }
}

/* eslint-enable jsx-a11y/anchor-is-valid */
/* eslint-enable react/no-access-state-in-setstate */

LanguageSelector.propTypes = {
  i18n: PropTypes.object.isRequired,
};

export default translate()(LanguageSelector);
