/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 08:19:58
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-11-21 13:13:12
 */
import { PENDING, FULFILLED, REJECTED } from 'redux-promise-middleware';
import { LOCATION_CHANGE } from 'react-router-redux';

import { HELP_ROUTE } from '../../routes';
import {
  TokenActionTypes, TokenTypes, Headers, ErrorCode,
} from '../../common/Constants';
import { getHeaderFromPayLoad } from '../../common/Util';
import i18n from '../../common/i18n';

const t = i18n.t.bind(i18n);

function getErrorMessage(error, tokenType) {
  let errorMessage = null;
  if (error.response) {
    if (error.response.data.message && error.response.data.message.includes('Tableau Employee')){
      errorMessage = ["This is not allowed for users with @tableau.com email addresses."];
    } else {
      const requestId = getHeaderFromPayLoad(error, Headers.requestId);
      switch (error.response.data.code) {
        case ErrorCode.USER_DEACTIVATED:
          errorMessage = [
            () => t(
              'Your account has been deactivated. '
                  + 'Please contact our <a href="{{link}}">Customer Service team</a> for help.',
              { link: HELP_ROUTE },
            ),
          ];
          break;
        case ErrorCode.FORBIDDEN_VIEW_EMAIL:
          errorMessage = ['You cannot generate a local email for an @tableausoftware.com or an @tableau.com account.'];
          break;
        case ErrorCode.USER_NOT_PENDING:
        case ErrorCode.NOT_FOUND:
          errorMessage = [
            'We were unable to access an un-activated account with the email you provided.',
            'Please try again.',
          ];
          break;
        case ErrorCode.CLIENT_NOT_FOUND_ERROR:
        case ErrorCode.AUTH0_BAD_REQUEST_ERROR:
          if (tokenType === 'resendActivation') {
            errorMessage = [
              '<p>Invalid request. Please try again here: <a href="https://id.tableau.com/resendActivation">https://id.tableau.com/resendActivation</a></p>',
            ];
          } else if (tokenType === 'resetPassword') {
            errorMessage = [
              '<p>Invalid request. Please try resetting your password here: <a href="https://id.tableau.com/resetPassword">https://id.tableau.com/resetPassword</a></p>',
            ];
          } else {
            errorMessage = ['Invalid request. Please try again'];
          }
          break;
        default:
          errorMessage = ["We're sorry! An unknown error has occurred.", '<br />', `RequestId: ${requestId}`];
          break;
      }
    }
  } else {
    errorMessage = ["We're sorry! An unknown error has occurred."];
  }

  return errorMessage;
}

function getSuccessMessage(type) {
  let result = null;
  if (type === TokenTypes.RESEND_ACTIVATION) {
    result = [
      'We have processed your request.',
      'You will receive a new email containing a link to confirm your Tableau account registration.',
      'You must follow the instructions in the email in order to activate your account. '
        + 'An old activation email will no longer work.',
    ];
  } else if (type === TokenTypes.RESET_PASSWORD) {
    result = [
      "We've sent you an email with a link to finish resetting your password.",
      '<br />',
      "Can't find the email? Try checking your spam folder.",
      '<br />',
      'If you still can\'t log in, have us <a href="/resetPassword">resend the email</a> or <a href="/help">contact help</a>.',
    ];
  }

  return result;
}

export default function reducer(
  state = {
    clientId: null,
    emailContent: null,
    emailSubject: null,
    emailTo: null,
    errorMessage: null,
    isFetching: false,
    sent: false,
    successMessage: null,
    requestid: '',
    tokenActionUrl: null
  },
  action,
) {
  switch (action.type) {
    case LOCATION_CHANGE: {
      return {};
    }
    case `${TokenActionTypes.POST_TOKEN}_${PENDING}`: {
      return { ...state, isFetching: true, requestId: '' };
    }
    case `${TokenActionTypes.POST_TOKEN}_${FULFILLED}`: {
      const request = JSON.parse(action.payload.config.data);
      const token = action.payload.data;
      const originalTokenType = action.meta.tokenType;
      const successMessage = getSuccessMessage(originalTokenType);
      return {
        ...state,
        emailTo: request.email,
        generateLocally: request.viewEmail ? true : false,
        errorMessage: null,
        isFetching: false,
        sent: true,
        successMessage,
        tokenActionUrl: request.viewEmail ? token.actionUrl : '',
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }
    case `${TokenActionTypes.POST_TOKEN}_${REJECTED}`: {
      const tokenType = action.meta && action.meta.tokenType ? action.meta.tokenType : '';
      const errorMessage = getErrorMessage(action.payload, tokenType);
      return {
        ...state,
        isFetching: false,
        errorMessage,
        emailContent: null,
        requestId: getHeaderFromPayLoad(action.payload, Headers.requestId),
      };
    }
    default:
      return state;
  }
}
