import { translate } from 'react-i18next';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { getProfile } from '../../common/SessionManager';
import ValidationInput from './elements/ValidationInput';

class ChangePasswordForm extends Component {
  constructor(props) {
    super(props);
    this.state = { submitDisabled: false };
    this.title = 'Change Password';
    this.currentPasswordTitle = 'Current Password';
    this.passwordTitle = 'New Password';
    this.confirmPasswordTitle = 'Confirm New Password';
    this.buttonText = 'Change Password';
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.setState({ submitDisabled: false });
    }
  }

  onValidSubmit = (formData) => {
    this.setState({ submitDisabled: true });
    this.props.onSubmit(formData);
  };

  onInvalidSubmit = () => {
    this.setState({ submitDisabled: false });
  };

  mapInputs = inputs => ({
    oldPassword: inputs.currentPassword,
    newPassword: inputs.password,
  });

  render() {
    const { t } = this.props;

    return (
      <div>
        <Formsy
          className="grid--5 form--full-width"
          onValidSubmit={this.onValidSubmit}
          onInvalidSubmit={this.onInvalidSubmit}
          mapping={this.mapInputs}
          noValidate
        >
          <h1 className="title">{t(this.title)}</h1>
          {getProfile() && getProfile().tabOnlineServerGroupAdmin ? (
            <p>
              {t(
                'Passwords are case sensitive, 16-100 characters long. Requires at least one number (0-9), one uppercase letter, one lowercase letter, and one of the following characters: ! " \\ # $ % & \' ( ) * + , - . / : ; < = > ? @ [ ] ^ _ ` { | } ~. You cannot use previously used passwords.',
              )}
            </p>
          ) : (
            <p>
              {t(
                'Passwords are case sensitive, 8-100 characters long. Requires at least one number (0-9), one uppercase letter, one lowercase letter, and one of the following characters: ! " \\ # $ % & \' ( ) * + , - . / : ; < = > ? @ [ ] ^ _ ` { | } ~. You cannot use previously used passwords.',
              )}
            </p>
          )}

          <ValidationInput
            autoFocus
            id="currentPassword"
            maxLength="100"
            name="currentPassword"
            placeholder={t(this.currentPasswordTitle)}
            type="password"
            required
            value=""
            validationErrors={{
              isDefaultRequiredValue: t('This field is required.'),
            }}
            title={t(this.currentPasswordTitle)}
          />
          <ValidationInput
            id="password"
            maxLength="100"
            name="password"
            placeholder={t(this.passwordTitle)}
            type="password"
            required
            value=""
            validations="validPassword"
            validationErrors={{
              isDefaultRequiredValue: t('This field is required.'),
            }}
            validationError={t(
              'Passwords must contain at least one number, one uppercase letter, one lowercase letter, one special character, and be at least 8 characters long.',
            )}
            title={t(this.passwordTitle)}
          />
          <ValidationInput
            id="confirmPassword"
            maxLength="100"
            name="confirmPassword"
            placeholder={t(this.confirmPasswordTitle)}
            type="password"
            required
            value=""
            validations="equalsField:password"
            validationErrors={{
              isDefaultRequiredValue: t('This field is required.'),
            }}
            validationError={t('This must match the Password field above.')}
            title={t(this.confirmPasswordTitle)}
          />
          <div className="form-buttons">
            <button
              type="submit"
              className="cta cta--secondary"
              id="changePasswordButton"
              disabled={this.state.submitDisabled}
            >
              {t(this.buttonText)}
            </button>
          </div>
        </Formsy>
      </div>
    );
  }
}

ChangePasswordForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isFetching: PropTypes.bool.isRequired,
};
export default translate()(ChangePasswordForm);
