/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:23:29
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2017-11-20 14:29:22
 */
const originalConsole = window.console;
const Console = {
  log(message, ...params) {
    if (originalConsole && (process.env.NODE_ENV !== 'production' || window.REACT_APP_DEBUG === 'true')) {
      if (params.length > 0) {
        originalConsole.log(message, ...params);
      } else {
        originalConsole.log(message);
      }
    }
  },
};

export default Console;
