/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:22:43
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-07-03 15:51:08
 */
import { withFormsy } from 'formsy-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

class ValidationInput extends Component {
  constructor(props) {
    super(props);
    if (props.value) {
      props.setValue(props.value);
    }
  }

  render() {
    const {
      getValue, id, isPristine, setValue, showError, showRequired, fixedErrorField,
    } = this.props;

    const changeValue = (event) => {
      setValue(event.currentTarget.value);
    };

    const onBlur = (event) => {
      setValue(event.currentTarget.value);
    };
    // showRequired() is true when the value is empty and the required prop is
    // passed to the input.
    // showError() is true when the value typed is invalid

    // An error message is returned ONLY if the component is invalid
    // or the server has returned an error message
    const errorMessage = this.props.getErrorMessage();
    const invalid = !isPristine() && (showRequired() || showError()); // This is needed so that invalid field errors display on submit.
    const isActive = getValue() && getValue().length > 0;
    const errorFieldStyle = fixedErrorField
      ? { visibility: invalid ? 'visible' : 'hidden' }
      : { display: invalid ? '' : 'none' };

    return (
      <div
        className={`form__field form-field has-float-label
                ${invalid ? 'form-field--invalid' : ''}`}
      >
        <label htmlFor={this.props.name} className={`form-field__label ${isActive ? 'is-active' : ''}`}>
          {this.props.title}
        </label>
        <input
          autoFocus={this.props.autoFocus}
          className={`required attr_check  ${invalid ? 'form-field__error' : ''}`}
          formNoValidate
          id={id}
          maxLength={this.props.maxLength}
          name={this.props.name}
          onBlur={onBlur}
          onChange={changeValue}
          placeholder={this.props.placeholder}
          type={this.props.type}
          value={getValue()}
        />
        <div id={`${id}.errorField`} className="form-field__description" style={errorFieldStyle}>
          <div
            id={`${id}.errorMessage`}
            htmlFor={id}
            className="validation-error form-field__error"
            style={{ display: 'block' }}
          >
            {errorMessage}
          </div>
        </div>
      </div>
    );
  }
}

ValidationInput.propTypes = {
  autoFocus: PropTypes.bool,
  getErrorMessage: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isPristine: PropTypes.func.isRequired,
  maxLength: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  placeholder: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  showRequired: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  type: PropTypes.string,
  fixedErrorField: PropTypes.bool,
  value: PropTypes.string,
};

ValidationInput.defaultProps = {
  autoFocus: null,
  fixedErrorField: false,
  type: 'text',
  value: '',
};

export default withFormsy(ValidationInput);
