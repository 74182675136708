/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-09 12:09:25
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2018-04-17 10:13:34
 */
import { addValidationRule } from 'formsy-react';

addValidationRule('validPassword', (values, value) => {
  const val = value || '';
  let test = true;
  if (!value || value === '') {
    return test;
  }
  if (val.length < 8 || val.length > 255) {
    test = false;
  }

  if (!value.match(/(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[#!$%&'"()*+,-./\\:;<=>?@[\]^_`{|}~]).{8,100}/)) {
    test = false;
  }
  return test;
});

addValidationRule('validPhone', (values, value) => {
  const val = value || '';
  if (!value || value === '') {
    return true;
  }
  let result = true;
  if (val.trim().length > 40) {
    result = false;
  }
  if (containsControlCharacters(val)) {
    return false;
  }
  return result;
});

addValidationRule('validUserAlias', (values, value) => {
  const val = value || '';

  if (!val || val === '') {
    return true;
  }
  let result = true;
  if (val.trim().length > 100 || val.trim().length < 3) {
    result = false;
  }
  if (containsControlCharacters(val)) {
    return false;
  }
  return result;
});

addValidationRule('validName', (values, value) => {
  const val = value || '';

  if (!val || val === '') {
    return true;
  }
  let result = true;
  if (val.length < 1 || val.trim().length === 0) {
    result = false;
  }
  if (containsControlCharacters(val)) {
    return false;
  }
  return result;
});

addValidationRule('validSpecialCharacters', (values, value) => {
  const val = value || '';

  if (!val || val === '') {
    return true;
  }
  let result = true;
  if (containsControlCharacters(val)) {
    return false;
  }
  return result;
});

addValidationRule('validNameSearch', (values, value) => {
  const val = value || '';

  if (!val || val === '') {
    return true;
  }
  let result = false;
  if (val.match(/^(?:(?=[*])[*]|^[^0-9~`!@#$%^&*()+_={}|[\]\\:";<>?,/]+)$/)) {
    result = true;
  }
  return result;
});

addValidationRule('validNumberSearch', (values, value) => {
  const val = value || '';

  if (!val || val === '') {
    return true;
  }
  let result = false;
  if (val.match(/^(?:(?=[*])[*]|^[0-9_\-.()x/\\[\]]+)$/)) {
    result = true;
  }
  return result;
});

addValidationRule('validStringSearch', (values, value) => {
  const val = value || '';

  if (!val || val === '') {
    return true;
  }
  let result = false;
  if (val.match(/^(?:(?=[*])[*]|^[^*\\;]+)$/)) {
    result = true;
  }
  return result;
});

// Check for a hidden character
const containsControlCharacters = val => /[\b]/.test(val);
