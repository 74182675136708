/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-08 19:41:46
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2018-01-19 09:14:33
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';
import { translate } from 'react-i18next';

class RadioGroup extends Component {
  constructor(props) {
    super(props);
    this.props.setValue(this.props.value);
  }

  render() {
    const {
      items, name, title, setValue, getValue, type, t,
    } = this.props;

    const changeValue = (event) => {
      setValue(event.currentTarget.value);
    };

    return (
      <div className="form-field form__field">
        <label htmlFor="membership" className="form-field__label">
          {title}
        </label>
        <div className="field-list checkboxes">
          {items.map(item => (
            <div key={item.id}>
              <input
                type={type}
                name={name}
                id={item.value}
                onChange={changeValue}
                value={item.value}
                checked={getValue() === item.value}
              />
              <label htmlFor={item.id} className="form-field__label">
                {t(item.label)}
              </label>
              {' '}
              <br />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

RadioGroup.propTypes = {
  getValue: PropTypes.func.isRequired,
  items: PropTypes.array.isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  title: PropTypes.string,
  type: PropTypes.string,
  value: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired,
};

RadioGroup.defaultProps = {
  title: '',
  type: 'radio',
};
export default translate()(withFormsy(RadioGroup));
export { RadioGroup as PureRadioGroup }; // for unit testing
