/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:21:48
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2017-11-20 14:31:15
 */
import React, { Component } from 'react';

class Loader extends Component {
  render() {
    const style = {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'center',
      height: '100vh',
      width: '100vw',
      top: 200,
      bottom: 0,
      left: 0,
      right: 0,
      backgroundColor: 'white',
    };

    return (
      <div className="loader" style={style}>
        <div className="loader__animation" />
        <span className="loader__message" />
      </div>
    );
  }
}

export default Loader;
