/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:21:42
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2017-11-20 14:31:19
 */
import React from 'react';
import PropTypes from 'prop-types';
import { MessageType } from '../common/Constants';

const Message = props => (
  <div id={`div.${props.id}`} className={`messages messages--${props.messageType}`}>
    <p id={`p.${props.id}`} dangerouslySetInnerHTML={{ __html: props.text }} />
  </div>
);

Message.propTypes = {
  text: PropTypes.string.isRequired,
  messageType: PropTypes.oneOf([MessageType.error, MessageType.notice, MessageType.warning]).isRequired,
  id: PropTypes.string.isRequired,
};

export default Message;
