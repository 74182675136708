import { addValidationRule } from 'formsy-react';

const flattenArray = (accumulator, currentValue, index) => {
  let result = accumulator;
  if (index === 1) {
    result = [];
    result.push(accumulator);
  }
  if (Array.isArray(currentValue)) {
    result = result.concat(currentValue);
  } else {
    result.push(currentValue);
  }
  return result;
};

const mapStateOptions = (options, label) => options.map((option, index) => {
  const result = {};
  const key = Object.keys(option)[0];
  const value = option[key];
  result.id = index.toString();
  result.label = value;
  if (index === 0) {
    // replace -- with label such as state
    result.label = label;
  }
  result.value = key;
  return result;
});

const addFieldValidation = (rule) => {
  addValidationRule('validAddressField', (values, value) => {
    if (!value || value === '') {
      return true;
    }
    return value && value.match(rule);
  });
};

export const mapFields = (fields, startingIndex) => {
  const mappedFields = fields.map((field, index) => {
    let result = {};
    const key = Object.keys(field)[0];
    const value = field[key];
    // Is this field an object or array
    if (Array.isArray(value)) {
      result = mapFields(value, index);
    } else {
      result.id = startingIndex ? startingIndex + index : index; // fix index so its sequential when we flatten
      result.type = key;
      result.label = value.label;
      result.format = value.format;
      result.example = value.eg;
      if (value.options) {
        result.options = mapStateOptions(value.options, value.label);
      }
      if (value.format) {
        addFieldValidation(value.format);
      }
    }
    return result;
  });
  return mappedFields.reduce(flattenArray);
};

export const getCountryConfig = (config, iso) => config.options.find(c => c.iso === iso);
