import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import History from '../common/History';

import { MessageType } from '../common/Constants';
import * as UserActions from '../redux/actions/userActions';
import LoaderOverlay from '../components/LoaderOverlay';
import Message from '../components/Message';
import UserSearchForm from '../components/forms/UserSearchForm';
import UserSearchResults from '../components/forms/UserSearchResults';
import { getProfile } from '../common/SessionManager';
import { translateArray } from '../common/Util';
import { ADMIN_ROUTE } from '../routes';

export class UserSearch extends Component {
  profile = getProfile();

  componentDidUpdate() {
    // Scroll to the top of the page after updating
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
  }

  onSubmit = (userInfo) => {
    this.props.dispatchSearchUsers(userInfo);
  };

  render() {
    const {
      t,
      isFetching,
      errorMessage,
      success,
      currentUsers,
      pastUsers,
      notFound,
      searchRequestId,
      uidSearch,
      emailSearch,
      invalidWildCardSearch,
      cannotSearchEmailChangeHistory,
    } = this.props;
    document.title = `Tableau - ${this.props.t('Profile Search')}`;
    const singleResult = currentUsers.length === 1 && pastUsers.length === 0;
    if (singleResult && (emailSearch || uidSearch)) {
      const userEmail = currentUsers[0].email;
      History.push({
        pathname: ADMIN_ROUTE,
        search: `?email=${encodeURIComponent(userEmail)}`,
      });
    }
    return (
      <section className="section">
        <div className="content-container" id="loading-overlay-content">
          {isFetching && <LoaderOverlay />}
          {errorMessage && (
            <Message id="errorMessage" text={translateArray(errorMessage)} messageType={MessageType.error} />
          )}
          {success && !(singleResult && (emailSearch || uidSearch)) && (
            <Message id="successMessage" text={t('Search succeeded.')} messageType={MessageType.notice} />
          )}
          {!errorMessage && notFound && (
            <Message
              id="successMessage"
              text={t('Search returned no results. Please try again with different search values.')}
              messageType={MessageType.notice}
            />
          )}
          {!(singleResult && (emailSearch || uidSearch)) && (
            <UserSearchForm
              onSubmit={this.onSubmit}
              showGroups={this.profile && this.profile.showGroups}
              isFetching={isFetching}
            />
          )}
          {!notFound
            && !errorMessage
            && !(singleResult && (emailSearch || uidSearch))
            && (currentUsers.length > 0 || pastUsers.length > 0) && (
              <UserSearchResults
                currentUsers={currentUsers}
                pastUsers={pastUsers}
                emailChangeHistory={emailSearch}
                invalidWildCardSearch={invalidWildCardSearch}
                cannotSearchEmailChangeHistory={cannotSearchEmailChangeHistory}
              />
          )}
        </div>
        <input type="hidden" id="searchRequestId" value={searchRequestId} />
      </section>
    );
  }
}
UserSearch.propTypes = {
  t: PropTypes.func.isRequired,
  dispatchSearchUsers: PropTypes.func.isRequired,
  errorMessage: PropTypes.array,
  success: PropTypes.bool,
  isFetching: PropTypes.bool,
  currentUsers: PropTypes.array,
  pastUsers: PropTypes.array,
  notFound: PropTypes.bool,
  searchRequestId: PropTypes.string,
  emailSearch: PropTypes.bool,
  uidSearch: PropTypes.bool,
  invalidWildCardSearch: PropTypes.string,
  cannotSearchEmailChangeHistory: PropTypes.bool,
};

UserSearch.defaultProps = {
  errorMessage: null,
  success: false,
  currentUsers: [],
  pastUsers: [],
  isFetching: false,
  notFound: false,
  searchRequestId: '',
  emailSearch: false,
  uidSearch: false,
  invalidWildCardSearch: '',
  cannotSearchEmailChangeHistory: false,
};

export const mapStateToProps = state => ({
  success: state.userSearchReducer.success,
  notFound: state.userSearchReducer.notFound,
  errorMessage: state.userSearchReducer.errorMessage,
  isFetching: state.userSearchReducer.isFetching,
  currentUsers: state.userSearchReducer.currentUsers,
  pastUsers: state.userSearchReducer.pastUsers,
  noResults: state.userSearchReducer.noResults,
  searchRequestId: state.userSearchReducer.requestId,
  emailSearch: state.userSearchReducer.emailSearch,
  uidSearch: state.userSearchReducer.uidSearch,
  invalidWildCardSearch: state.userSearchReducer.invalidWildCardSearch,
  cannotSearchEmailChangeHistory: state.userSearchReducer.cannotSearchEmailChangeHistory,
});

const mapDispatchToProps = dispatch => ({
  dispatchSearchUsers: bindActionCreators(UserActions.searchUsers, dispatch),
});
export default translate()(connect(mapStateToProps, mapDispatchToProps)(UserSearch));
