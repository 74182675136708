/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-17 21:21:38
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-12 14:43:54
 */

import { UserGroups } from '../common/Constants';

// How the groups are modeled differ from the UI.
// so lets map them to something that makes sense.
function mapGroups(user, userModel) {
  if (user.authorization && user.authorization.groups) {
    const groups = user.authorization.groups;
    userModel.emailViewers = groups.find(group => group === UserGroups.emailViewers);
    userModel.permissionGranters = groups.find(group => group === UserGroups.permissionGranters);
    userModel.group = groups.find(
      group => group !== UserGroups.emailViewers && group !== UserGroups.permissionGranters,
    );
    userModel.group = !userModel.group ? UserGroups.None : userModel.group;
    userModel.memberOfGroups = groups.join('/');
  } else {
    userModel.group = '';
    userModel.emailViewers = '';
    userModel.permissionGranters = '';
  }
}
// Takes an auth0 user object and converts it to a UserModel
export const mapUser = (user) => {
  const userModel = {
    company: user.company,
    country: user.country,
    thoroughfare: user.thoroughfare,
    premise: user.premise,
    localityname: user.localityName,
    administrativearea: user.administrativeArea,
    dependent_localityname: user.dependentLocalityname,
    postalcode: user.postalCode,
    created: user.createdAt,
    department: user.department,
    email: user.email,
    emailViewers: null,
    firstName: user.firstName,
    fullName: `${user.firstName} ${user.lastName}`,
    group: null,
    industry: user.industry,
    jobRole: user.jobRole,
    title: user.title,
    lastLogin: user.lastLogin,
    lastName: user.lastName,
    legacyGroup: user.legacyGroup,
    memberOfGroups: null,
    permissionGranters: null,
    phone: user.phone ? user.phone : '',
    status: user.status,
    uid: user.uid,
    updated: user.updatedAt,
    userAlias: user.userAlias,
    userId: user.userId,
    verified: user.emailVerified,
    language: user.language,
    emailHistory: user.emailHistory,
    loginHistory: user.clientLastLoginInfo,
  };
  mapGroups(user, userModel);
  return userModel;
};

// Takes an array of auth0 users and returns a list of UserModels
export const mapUsers = users => users.map(user => mapUser(user));

const addressElements = [
  'thoroughfare',
  'premise',
  'localityname',
  'administrativeArea',
  'postalcode',
  'dependentLocalityName',
];

const mapCommonInfo = (userModel, inputs) => {
  if (inputs.country === '') {
    addressElements.forEach((key) => {
      inputs[key] = '';
    });
  } else {
    addressElements.forEach((key) => {
      inputs[key] = inputs[key] === undefined ? '' : inputs[key];
    });
  }
  const result = {
    administrativeArea: inputs.administrativearea !== userModel.administrativearea ? inputs.administrativearea : null,
    company: inputs.company !== userModel.company ? inputs.company : null,
    country: inputs.country !== userModel.country ? inputs.country : null,
    department: inputs.department !== userModel.department ? inputs.department : null,
    firstName: inputs.firstName !== userModel.firstName ? inputs.firstName : null,
    industry: inputs.industry !== userModel.industry ? inputs.industry : null,
    jobRole: inputs.jobRole !== userModel.jobRole ? inputs.jobRole : null,
    language: inputs.language,
    lastName: inputs.lastName !== userModel.lastName ? inputs.lastName : null,
    localityName: inputs.localityname !== userModel.localityname ? inputs.localityname : null,
    phone: inputs.phone !== userModel.phone ? inputs.phone : null,
    postalCode: inputs.postalcode !== userModel.postalcode ? inputs.postalcode : null,
    premise: inputs.premise !== userModel.premise ? inputs.premise : null,
    dependentLocalityname:
      inputs.dependent_localityname !== userModel.dependent_localityname ? inputs.dependent_localityname : null,
    thoroughfare: inputs.thoroughfare !== userModel.thoroughfare ? inputs.thoroughfare : null,
    title: inputs.title !== userModel.title ? inputs.title : null,
    userAlias: inputs.userAlias !== userModel.userAlias ? inputs.userAlias : null,
    emailHistory: inputs.emailHistory !== userModel.emailHistory ? inputs.emailHistory : null,
  };

  return result;
};

// Takes a userModel and input values from a form and returns a user object in the auth0 format.
export const mapInputsFromUserEdit = (userModel, inputs) => {
  const groups = [];
  let groupsChanged = false;
  if (inputs.group !== null && userModel.group !== inputs.group) {
    groupsChanged = true;
  }
  if (inputs.emailViewers !== null && userModel.emailViewers !== inputs.emailViewers) {
    groupsChanged = true;
  }
  if (inputs.permissionGranters !== null && userModel.permissionGranters !== inputs.permissionGranters) {
    groupsChanged = true;
  }

  if (groupsChanged) {
    groups.push(inputs.group);
    if (inputs.emailViewers && inputs.emailViewers !== '') {
      groups.push(inputs.emailViewers);
    }
    if (inputs.permissionGranters && inputs.permissionGranters !== '') {
      groups.push(inputs.permissionGranters);
    }
  }

  const userInfo = mapCommonInfo(userModel, inputs);
  userInfo.status = inputs.status !== userModel.status ? inputs.status : null;

  const user = {
    userId: userModel.userId,
    uid: userModel.uid,
    ...userInfo,
  };

  if (groups.length > 0) {
    user.authorization = { groups };
  }
  window.inputs = inputs;
  window.user = user;
  return user;
};

// Takes a userModel and input values from a form and returns a user object in the auth0 format.
export const mapInputsFromMyProfileEdit = (userModel, inputs) => {
  const userInfo = mapCommonInfo(userModel, inputs);
  const user = {
    userId: userModel.userId,
    uid: userModel.uid,
    ...userInfo,
  };
  return user;
};

// Starts with mapInputsFromMyProfileEdit and decorates with publicData.
export const mapInputsFromAdditionalInfoEdit = (userModel, inputs) => {
  const myProfileEditUserData = mapInputsFromMyProfileEdit(userModel, inputs);

  // This uses the Checkbox component, where possible values are the value prop
  // (a string) if checked or '' if unchecked. This converts to Boolean for the API.
  const marketingEmailOptinBoolean = Boolean(inputs.marketingEmailOptIn);

  const publicData = {
    codeOfConductAcceptance: inputs.codeOfConductAcceptance,
    marketingEmailOptIn: marketingEmailOptinBoolean,
  };
  const user = {
    publicData,
    ...myProfileEditUserData,
  };
  return user;
};
