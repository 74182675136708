/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:19:57
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-28 15:27:54
 */
import axios from 'axios';
import { logPromiseError } from '../../common/Logger';
import { getHeaders } from '../../common/SessionManager';

import { TokenActionTypes, EndPoints, TokenTypes } from '../../common/Constants';

const tokenUrl = `${window.REACT_APP_API_URL}${EndPoints.Tokens}`;

export function patchToken(tokenId, tokenType, body, language) {
  return (dispatch) => {
    getHeaders(language).then((headers) => {
      dispatch({
        type: TokenActionTypes.PATCH_TOKEN,
        meta: {
          // preserve the original token info, this is to determine UI state on error.
          tokenType,
        },
        payload: axios.post(`${tokenUrl}/${tokenId}`, body, { headers }),
      })
        .then((response) => {
          if (response.action.payload.data) {
            const goto = response.action.payload.data.gotoUrl;
            const autoGoto = response.action.payload.data.autoGoto;
            const loginUrl = response.action.payload.data.loginUrl;
            if (loginUrl && loginUrl.includes('https')) {
              window.location = loginUrl;
            } else if (goto && autoGoto) {
              window.location = goto;
            }
          }
        })
        .catch((error) => {
          logPromiseError('tokenActions.patchToken()', error);
        });
    });
  };
}

export function fetchToken(tokenId) {
  return (dispatch) => {
    getHeaders().then((headers) => {
      dispatch({
        type: TokenActionTypes.GET_TOKEN,
        payload: axios.get(`${tokenUrl}/${tokenId}`, { headers }),
      })
        .then((response) => {
          const token = response.value.data;
          // If the token is changeemail or a standard activation token
          // and if the token isn't expired or already used (verified) then patch the token
          // because no additional info from the user is necessary.
          if (
            !token.expired
            && !token.verified
            && (token.type === TokenTypes.CHANGE_EMAIL
              || token.type === TokenTypes.STANDARD_ACTIVATION
              || token.type === TokenTypes.SALESFORCE_EMAIL_ACTIVATION)
          ) {
            return dispatch(patchToken(tokenId, token.type, { verified: true }));
          }
          if (token.verified && token.gotoUrl && token.activationTitle && token.activationTitle === 'TABLEAU_CLOUD_ACTIVATION') {
            window.location = token.gotoUrl;
          }
          return null;
        })
        .catch((error) => {
          logPromiseError('tokenActions.fetchToken()', error);
        });
    });
  };
}

export function postToken(tokenType, email, viewEmail, language, clientId, relayState) {
  return (dispatch) => {
    getHeaders(language, clientId, null, relayState).then((headers) => {
      dispatch({
        type: TokenActionTypes.POST_TOKEN,
        meta: {
          // preserve the original token info, this is to determine correct error messages.
          tokenType,
        },
        payload: axios.post(
          tokenUrl,
          {
            email,
            type: tokenType,
            viewEmail,
          },
          { headers },
        ),
      }).catch((error) => {
        logPromiseError('tokenActions.postToken()', error);
      });
    });
  };
}

// This is for change email, it's different from a regular post token since it's secure and requires the userId
export function postEmailToken(uid, email, language) {
  return (dispatch) => {
    const body = {
      uid,
      type: TokenTypes.CHANGE_EMAIL,
      email,
    };
    getHeaders(language).then((headers) => {
      dispatch({
        type: TokenActionTypes.POST_EMAIL_TOKEN,
        payload: axios.post(tokenUrl, body, { headers }),
      }).catch((error) => {
        logPromiseError('tokenActions.postEmailToken()', error);
      });
    });
  };
}
