import React, { Component } from 'react';
import { withFormsy } from 'formsy-react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

export const selectTestId = 'ValidationSelect-select';

class ValidationSelect extends Component {
  constructor(props) {
    super(props);
    if (props.value) {
      props.setValue(props.value);
      if (props.onChange) {
        props.onChange(props.value);
      }
    }
  }

  isValidSelection = (options, value) => {
    let result = true;
    if (!value || value === '' || !options.find(element => element.value === value)) {
      result = false;
    }
    return result;
  };

  render() {
    const {
      getValue,
      id,
      isPristine,
      name,
      onChange,
      options,
      setValue,
      showError,
      showRequired,
      t,
      title,
      translateContents,
    } = this.props;
    const isValidSelection = this.isValidSelection(options, getValue());

    const handleChange = (event) => {
      setValue(event.currentTarget.value);
      if (onChange) {
        onChange(event.currentTarget.value);
      }
    };

    const onBlur = (event) => {
      setValue(event.currentTarget.value);
    };

    // showRequired() is true when the value is empty and the required prop is
    // passed to the input.
    // showError() is true when the value typed is invalid

    // An error message is returned ONLY if the component is invalid
    // or the server has returned an error message
    const errorMessage = this.props.getErrorMessage();
    const invalid = !isPristine() && (showRequired() || showError()); // This is needed so that invalid field errors display on submit.
    const isActive = getValue() && getValue().length > 0;
    const errorFieldStyle = { display: invalid ? '' : 'none' };

    return (
      <div className={`form-field form__field has-float-label ${invalid ? 'form-field--invalid' : ''}`}>
        <label className={`form-field__label ${isActive ? 'is-active' : ''}`} htmlFor={id}>
          {translateContents ? t(title) : title}
        </label>
        <select
          autoFocus={this.props.autoFocus}
          className={`${isValidSelection ? '' : 'is-empty'} ${invalid ? 'form-field__error' : ''}`}
          data-test-id={selectTestId}
          formNoValidate
          id={id}
          name={name}
          onBlur={onBlur}
          onChange={handleChange}
          size="1"
          value={getValue()}
        >
          {options.map(option => (
            <option key={option.id} value={option.value}>
              {translateContents ? t(option.label) : option.label}
            </option>
          ))}
        </select>
        <div id={`${id}.errorField`} className="form-field__description" style={errorFieldStyle}>
          <div
            id={`${id}.errorMessage`}
            htmlFor={id}
            className="validation-error form-field__error"
            style={{ display: 'block' }}
          >
            {errorMessage}
          </div>
        </div>
      </div>
    );
  }
}

ValidationSelect.propTypes = {
  autoFocus: PropTypes.bool,
  getErrorMessage: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isPristine: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setValue: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  showRequired: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  translateContents: PropTypes.bool,
  value: PropTypes.string,
};

ValidationSelect.defaultProps = {
  autoFocus: null,
  value: '',
  onChange: null,
  translateContents: true,
};

export default translate()(withFormsy(ValidationSelect));
// export { ValidationSelect as PureSelect }; // for unit testing
