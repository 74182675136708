/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:21:45
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-11-27 12:19:43
 */
import React, { Component } from 'react';

class LoaderOverlay extends Component {
  render() {
    const style = {
      top: '50%',
      backgroundColor: 'white',
    };

    return (
      <div className="loading-overlay">
        <div className="loader" style={style}>
          <div className="loader__animation" />
          {/* <div className="loader__message">
          </div> */}
        </div>
      </div>
    );
  }
}

export default LoaderOverlay;
