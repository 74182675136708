import { withFormsy } from 'formsy-react';
import React, { Component } from 'react';
import PropTypes from 'prop-types';

export const checkboxTestId = 'ValidationCheckbox-checkbox';

class ValidationCheckbox extends Component {
  constructor(props) {
    super(props);
    if (props.value) {
      props.setValue(props.value);
    }
    this.state = { isChecked: props.checked };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) {
      this.setState({ isChecked: this.props.checked });
    }
  }

  render() {
    const {
      getValue, id, isPristine, setValue, showError, showRequired,
    } = this.props;
    const { isChecked } = this.state;

    const changeValue = (event) => {
      setValue(event.currentTarget.checked);
      this.setState({ isChecked: event.currentTarget.checked });
    };

    const onBlur = (event) => {
      setValue(event.currentTarget.checked);
    };
    // showRequired() is true when the value is empty and the required prop is
    // passed to the input.
    // showError() is true when the value typed is invalid

    // An error message is returned ONLY if the component is invalid
    // or the server has returned an error message
    const errorMessage = this.props.getErrorMessage();
    const invalid = !isPristine() && (showRequired() || showError()); // This is needed so that invalid field errors display on submit.
    const isActive = getValue() && getValue().length > 0;
    const errorFieldStyle = { display: invalid ? '' : 'none' };

    return (
      <div className={`ValidationCheckbox form__field form-field ${invalid ? 'form-field--invalid' : ''}`}>
        <div className="field-list checkboxes">
          <div className="ValidationCheckbox__field">
            <input
              checked={isChecked}
              className={`ValidationCheckbox__checkbox required attr_check ${invalid ? 'form-field__error' : ''}`}
              data-test-id={checkboxTestId}
              formNoValidate
              id={id}
              name={this.props.name}
              onBlur={onBlur}
              onChange={changeValue}
              type="checkbox"
              value={getValue()}
            />
            <div className="ValidationCheckbox__labelContainer">
              <label
                htmlFor={this.props.name}
                className={`ValidationCheckbox__label form-field__label ${isActive ? 'is-active' : ''}`}
              >
                {this.props.title}
              </label>
              <div id={`${id}.errorField`} className="form-field__description" style={errorFieldStyle}>
                <div
                  id={`${id}.errorMessage`}
                  htmlFor={id}
                  className="validation-error form-field__error"
                  style={{ display: 'block' }}
                >
                  {errorMessage}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

ValidationCheckbox.propTypes = {
  checked: PropTypes.bool,
  getErrorMessage: PropTypes.func.isRequired,
  getValue: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  isPristine: PropTypes.func.isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  showError: PropTypes.func.isRequired,
  showRequired: PropTypes.func.isRequired,
  title: PropTypes.node.isRequired,
  value: PropTypes.string,
};

ValidationCheckbox.defaultProps = {
  checked: false,
  value: '',
};

export default withFormsy(ValidationCheckbox);
