/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-08 19:41:46
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2018-01-19 09:39:23
 */

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withFormsy } from 'formsy-react';

export const checkboxTestId = 'Checkbox-checkbox';

class Checkbox extends Component {
  constructor(props) {
    super(props);
    const val = props.checked ? props.value : '';
    this.props.setValue(val);
    this.state = { isChecked: props.checked };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.checked !== this.props.checked) {
      const val = this.props.checked ? this.props.value : '';
      this.props.setValue(val);
      this.setState({ isChecked: this.props.checked });
    }
  }

  render() {
    const {
      id, label, value, name, setValue, type,
    } = this.props;
    const { isChecked } = this.state;

    const changeValue = (event) => {
      const val = event.currentTarget.checked ? value : '';
      setValue(val);
      this.setState({ isChecked: event.currentTarget.checked });
    };

    return (
      <div className="Checkbox form-field form__field">
        <div className="field-list checkboxes">
          <div className="Checkbox__field" key={id}>
            <input
              checked={isChecked}
              className="Checkbox__checkbox"
              data-test-id={checkboxTestId}
              id={id}
              name={name}
              onChange={changeValue}
              type={type}
            />
            <label htmlFor={id} className="Checkbox__label form-field__label">
              {label}
            </label>
            {' '}
            <br />
          </div>
        </div>
      </div>
    );
  }
}

Checkbox.propTypes = {
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  setValue: PropTypes.func.isRequired,
  type: PropTypes.string,
  label: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool,
};

Checkbox.defaultProps = {
  type: 'checkbox',
  checked: false,
};

export default withFormsy(Checkbox);
