import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { MessageType } from '../common/Constants';
import * as UserActions from '../redux/actions/userActions';
import ChangePasswordForm from '../components/forms/ChangePasswordForm';
import LoaderOverlay from '../components/LoaderOverlay';
import Message from '../components/Message';
import { getProfile } from '../common/SessionManager';
import { translateArray } from '../common/Util';
import { getChangePasswordErrorMessage } from '../common/MessageUtil';

class ChangePassword extends Component {
  onSubmit = (passwordInfo) => {
    const { changePassword, i18n } = this.props;
    changePassword(getProfile().uid, passwordInfo.oldPassword, passwordInfo.newPassword, i18n.language);
  };

  render() {
    const {
      t, success, error, isFetching, requestId,
    } = this.props;
    document.title = `Tableau - ${t('Change Password')}`;

    return (
      <section className="section">
        <div className="content-container" id="loading-overlay-content">
          {isFetching && <LoaderOverlay />}
          {error && (
            <Message
              id="errorMessage"
              text={translateArray(getChangePasswordErrorMessage(error))}
              messageType={MessageType.error}
            />
          )}
          {success && (
            <Message
              id="successMessage"
              text={t('Your password was successfully changed.')}
              messageType={MessageType.notice}
            />
          )}
          {!success && <ChangePasswordForm onSubmit={this.onSubmit} isFetching={isFetching} />}
        </div>
        <input type="hidden" id="requestId" value={requestId} />
      </section>
    );
  }
}

ChangePassword.propTypes = {
  t: PropTypes.func.isRequired,
  success: PropTypes.bool,
  error: PropTypes.object,
  isFetching: PropTypes.bool,
  changePassword: PropTypes.func.isRequired,
  i18n: PropTypes.object.isRequired,
  requestId: PropTypes.string,
};

ChangePassword.defaultProps = {
  error: null,
  success: false,
  isFetching: false,
  requestId: '',
};

const mapStateToProps = state => ({
  success: state.changePasswordReducer.success,
  error: state.changePasswordReducer.error,
  isFetching: state.changePasswordReducer.isFetching,
  requestId: state.changePasswordReducer.requestId,
});

const mapDispatchToProps = dispatch => ({
  changePassword: bindActionCreators(UserActions.changePassword, dispatch),
});
export default translate()(connect(mapStateToProps, mapDispatchToProps)(ChangePassword));
