import { translate } from 'react-i18next';
import Formsy from 'formsy-react';
import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { CHANGEMYEMAIL_ROUTE } from '../../routes';
import { getProfile } from '../../common/SessionManager';
import * as Data from '../../common/Data';
import * as UserModel from '../../models/UserModel';
import { ErrorMessages } from '../../common/Constants';
import Select from './elements/Select';
import ValidationInput from './elements/ValidationInput';

import AddressField from './elements/AddressField';
import getAddressConfig from './Data/AddressConfig';

class MyUserProfileForm extends Component {
  constructor(props) {
    super(props);
    this.state = { config: getAddressConfig(this.props.i18n.language), submitDisabled: false };
    this.setStateFromLanguage = this.setStateFromLanguage.bind(this);
    this.profile = getProfile();
    this.isAdmin = (this.profile && this.profile.profileAdmin) || (this.profile && this.profile.profileReader);
    this.email = this.profile ? this.profile.email : '';
  }

  componentDidMount() {
    this.props.i18n.on('languageChanged', this.setStateFromLanguage);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isFetching && !this.props.isFetching) {
      this.setState({ submitDisabled: false });
    }
  }

  componentWillUnmount() {
    this.props.i18n.off('languageChanged', this.setStateFromLanguage);
  }

  setStateFromLanguage(language) {
    this.setState({ config: getAddressConfig(language) });
  }

  onValidSubmit = (formData) => {
    this.setState({ submitDisabled: true });
    this.props.onSubmit(formData);
  };

  onInvalidSubmit = () => {
    this.setState({ submitDisabled: false });
  };

  mapInputs = inputs => UserModel.mapInputsFromMyProfileEdit(this.props.userModel, inputs);

  render() {
    const { t, userModel } = this.props;
    return (
      <div id="form" className="clearfix">
        <Formsy
          className="form form--sized"
          onValidSubmit={this.onValidSubmit}
          onInvalidSubmit={this.onInvalidSubmit}
          mapping={this.mapInputs}
          noValidate
        >
          <h1 className="title">{t('User Profile')}</h1>
          <div className="grid--12">
            <div className="form-field form__field">
              <label className="form-field__label" htmlFor="userEmail">
                {t('Email')}
              </label>
              <span className="label-tag label-tag--raw label-tag--email" id="userEmail">
                {this.email}
                &nbsp;
                {!this.isAdmin && (
                  <a
                    href={`${CHANGEMYEMAIL_ROUTE}?email=${encodeURIComponent(this.email)}`}
                    className="btn btn-default btn-xs"
                    role="button"
                  >
                    <span className="glyphicon glyphicon-edit">Edit</span>
                  </a>
                )}
              </span>
            </div>
          </div>
          <div className="grid--5">
            <ValidationInput
              autoFocus
              id="userAlias"
              maxLength="100"
              name="userAlias"
              placeholder={t('Username')}
              type="text"
              value={userModel.userAlias || ''}
              title={t('Username')}
              validations="validUserAlias"
              validationError={t(ErrorMessages.userAlias)}
              required
              validationErrors={{
                isDefaultRequiredValue: t('This field is required.'),
              }}
            />
            <ValidationInput
              autoFocus
              id="firstName"
              maxLength="40"
              name="firstName"
              placeholder={t('First Name')}
              type="text"
              required
              value={userModel.firstName || ''}
              validationErrors={{
                isDefaultRequiredValue: t('This field is required.'),
              }}
              validations="validName"
              validationError={t(ErrorMessages.firstName)}
              title={t('First Name')}
            />
            <ValidationInput
              id="lastName"
              maxLength="80"
              name="lastName"
              placeholder={t('Last Name')}
              type="text"
              required
              value={userModel.lastName || ''}
              validationErrors={{
                isDefaultRequiredValue: t('This field is required.'),
              }}
              validations="validName"
              validationError={t(ErrorMessages.lastName)}
              title={t('Last Name')}
            />
            <ValidationInput
              id="title"
              maxLength="80"
              name="title"
              placeholder={t('Title')}
              type="text"
              value={userModel.title || ''}
              validations="validSpecialCharacters"
              validationError={t(ErrorMessages.invalidField)}
              title={t('Title')}
            />
            <ValidationInput
              id="company"
              maxLength="80"
              name="company"
              placeholder={t('Company')}
              type="text"
              value={userModel.company || ''}
              validations="validSpecialCharacters"
              validationError={t(ErrorMessages.invalidField)}
              title={t('Company')}
            />
            <Select id="industry" name="industry" title="Industry" options={Data.industry} value={userModel.industry} />

            <Select
              id="department"
              name="department"
              title="Department"
              options={Data.department}
              value={userModel.department}
            />

            <Select id="jobRole" name="jobRole" title="Job Role" options={Data.jobrole} value={userModel.jobRole} />
            <Select
              id="language"
              name="language"
              title="Preferred Language"
              options={Data.LanguageData}
              value={userModel.language}
            />
          </div>
          <div className="grid--5">
            <ValidationInput
              id="phone"
              maxLength="80"
              name="phone"
              placeholder={t('Phone')}
              type="text"
              value={userModel.phone || ''}
              validations="validPhone"
              validationError={t(ErrorMessages.phoneNumber)}
              title={t('Phone')}
            />
            <AddressField name="addressField" config={this.state.config} userModel={this.props.userModel} />
            <div className="form-buttons">
              <button
                type="submit"
                className="cta cta--secondary"
                id="updateMyUserButton"
                disabled={this.state.submitDisabled}
              >
                {t('Update')}
              </button>
            </div>
          </div>
        </Formsy>
      </div>
    );
  }
}
MyUserProfileForm.propTypes = {
  t: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  userModel: PropTypes.object.isRequired,
  i18n: PropTypes.object.isRequired,
  isFetching: PropTypes.bool.isRequired,
};
export default translate()(MyUserProfileForm);
