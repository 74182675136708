/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:23:32
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-07-18 13:44:05
 */
import auth0 from 'auth0-js';

import {
  CALLBACK_ROUTE, ERROR_ROUTE, HOME_ROUTE, SILENTCALLBACK_ROUTE,
} from '../routes';
import History from './History';
import * as SessionManager from './SessionManager';
import Console from './Console';
import { logError } from './Logger';
import LocalStorage from './LocalStorage';

export default class Auth {
  constructor() {
    this.login = this.login.bind(this);
    this.logout = this.logout.bind(this);
    this.handleAuthentication = this.handleAuthentication.bind(this);

    const usesVanityDomains = window.REACT_APP_USES_VANITY_DOMAINS;

    const overrides = usesVanityDomains === 'true'
      ? {
        __tenant: window.REACT_APP_TENANT,
        __token_issuer: window.REACT_APP_ISSUER,
      }
      : null;

    const webAuth = new auth0.WebAuth({
      domain: window.REACT_APP_DOMAIN,
      clientID: window.REACT_APP_CLIENTID,
      redirectUri: window.REACT_APP_BASE_URL + CALLBACK_ROUTE,
      audience: `https://${window.REACT_APP_DOMAIN}/userinfo`,
      responseType: 'token id_token',
      scope: 'openid email',
      overrides,
    });

    this.auth0 = webAuth;
  }

  login(selectedLanguage, goTo) {
    const state = SessionManager.setState(goTo);
    this.auth0.authorize({
      state,
      audience: window.REACT_APP_API_AUDIENCE,
      prompt: 'login',
    });
  }

  handleAuthentication() {
    // Fix for FF44
    const verifyIdToken = !(navigator.userAgent.indexOf('Firefox/44') > -1);
    this.auth0.parseHash(
      { _idTokenVerification: verifyIdToken, __enableIdPInitiatedLogin: true },
      (err, authResult) => {
        Console.log('Handle authentication authResult.', authResult);
        if (authResult && authResult.accessToken && authResult.idToken) {
          if (authResult.state) {
            const state = SessionManager.getState(authResult.state);
            if (state) {
              SessionManager.setProfile(authResult);
              History.push(state.goTo);
            } else {
              History.push(HOME_ROUTE);
            }
          } else {
            this.renewAuth()
              .then(() => {
                Console.log('Renew auth succeeded.');
                History.push(HOME_ROUTE);
              })
              .catch((error) => {
                Console.log('Renew auth failed.');
                Console.log(error);
                History.push(ERROR_ROUTE);
              });
          }
        } else if (err) {
          Console.log('Handle authentication failed.', err);
          if (err.errorDescription === '`state` does not match.') {
            Console.log('Auth error was invalid state, redirecting to home.');
            History.push(HOME_ROUTE);
          } else {
            logError({
              error: err.error,
              description: err.errorDescription,
              callingMethod: 'Auth.handleAuthentication()',
            });
            History.push(ERROR_ROUTE);
          }
        }
      },
    );
  }

  handleSilentAuthentication() {
    const verifyIdToken = !(navigator.userAgent.indexOf('Firefox/44') > -1);
    this.auth0.parseHash({ _idTokenVerification: verifyIdToken, __enableIdPInitiatedLogin: true }, (err, result) => {
      Console.log('Handle silent authentication authResult.', result);
      Console.log('Handle silent authentication error.', err);
      const info = { error: err, authResult: result };
      // This call posts to the parent window, the handler is in the renewAuth method below.
      window.parent.postMessage({ info, type: 'auth0:silentcallback' }, `${window.REACT_APP_BASE_URL}/`);
    });
  }

  /*
    If both goTo and clientId are specified then the URL has to be whitelisted under the client level.
    If only goto is specified the URL has to be whitelisted under the tenant level.
    If only a clientId is specified the on success the user is redirected to the first logout URL
      white listed under that client.
  */
  logout(goTo, clientId) {
    SessionManager.clearSession();

    /*
      If there is a goto don't override clientId so the user is redirected to that URL

      If there isn't a goTo and clientId is not specified use the tableau-id-ui client Id.
      This is so the user will land on our /signout page at the least.
    */
    clientId = goTo ? clientId : clientId || window.REACT_APP_CLIENTID;
    const config = {
      client_id: clientId,
      returnTo: goTo,
    };
    this.auth0.logout(config);
  }

  renewAuth() {
    return new Promise((resolve, reject) => {
      const stateId = SessionManager.setState();
      // SSO Check
      this.auth0.renewAuth(
        {
          audience: window.REACT_APP_API_AUDIENCE,
          scope: 'openid email',
          responseType: 'token id_token',
          redirectUri: window.REACT_APP_BASE_URL + SILENTCALLBACK_ROUTE,
          usePostMessage: true,
          state: stateId,
          // postMessageDataType can be any value, to resolve issue with iFrame being closed by events other than auth0 such as redux dev tools
          // I'm using the state Id because it also helps solve race conditions if renew auth is called to renew token or by requirelogin
          // This was happening in the case of /admin?email= because we make a request as soon as the page mounts
          // at the same time RequireLogin checks if the user is authenticated.
          // postMessageDataType: `auth0:${stateId}`,
          postMessageDataType: 'auth0:silentcallback',
        },
        (err, result) => {
          Console.log('renewAuth Result', result);
          let auth0StateId = null;
          if (result && result.info.error) {
            auth0StateId = result.info.error.state;
          } else if (result && result.info.authResult) {
            auth0StateId = result.info.authResult.state;
          }

          const state = SessionManager.getState(auth0StateId);
          if (result && result.info.error) {
            // Found bug in Auth0 where nonces were not cleaned up from localstorage.
            // TODO: Remove this when bug is fixed.
            LocalStorage.removeItem(`com.auth0.auth.${result.info.error.state}`);
            reject(result.info.error);
          } else if (result && result.info.authResult && state) {
            // Make sure the state we sent is the same one that came back.
            SessionManager.setProfile(result.info.authResult);
            resolve(true);
          } else {
            reject(new Error('Something went wrong with silent Auth.'));
          }
        },
      );
    });
  }
}
