import storage from 'local-storage-fallback';
import Console from './Console';
import { logError } from './Logger';

const getItem = (key) => {
  let item = null;
  try {
    item = storage.getItem(key);
  } catch (ex) {
    Console.log('localStorage.getItem()', ex.code, ex.message);
    logError(
      {
        errorCode: ex.code,
        errorMessage: ex.message,
        callingMethod: 'LocalStorage.getItem()',
      },
      true,
    );
  }
  return item;
};

const setItem = (key, value) => {
  try {
    storage.setItem(key, value);
  } catch (ex) {
    Console.log('LocalStorage.setItem()', ex.code, ex.message);
    logError(
      {
        errorCode: ex.code,
        errorMessage: ex.message,
        callingMethod: 'LocalStorage.setItem()',
      },
      true,
    );
  }
};

const removeItem = (key) => {
  try {
    storage.removeItem(key);
  } catch (ex) {
    Console.log('LocalStorage.removeItem()', ex.code, ex.message);
    logError(
      {
        errorCode: ex.code,
        errorMessage: ex.message,
        callingMethod: 'LocalStorage.removeItem()',
      },
      true,
    );
  }
};

const clear = () => {
  try {
    storage.clear();
  } catch (ex) {
    Console.log('LocalStorage.clear()', ex.code, ex.message);
    logError(
      {
        errorCode: ex.code,
        errorMessage: ex.message,
        callingMethod: 'LocalStorage.clear()',
      },
      true,
    );
  }
};
const LocalStorage = {
  getItem,
  setItem,
  removeItem,
  clear,
};

export default LocalStorage;
