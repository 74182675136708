/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-09 12:09:16
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2017-11-20 14:33:59
 */
import React, { Component } from 'react';
import { translate } from 'react-i18next';
import PropTypes from 'prop-types';

import UserResultTable from './UserResultTable';
import UserResultView from './UserResultView';

class UserSearchResults extends Component {
  count = (currentUsers, pastUsers) => currentUsers.length + pastUsers.length;

  render() {
    const {
      t,
      currentUsers,
      pastUsers,
      emailChangeHistory,
      invalidWildCardSearch,
      cannotSearchEmailChangeHistory,
    } = this.props;

    return (
      <div>
        <h3 className="heading-highlight">
          {`${t('Currently')} ${this.count(currentUsers, pastUsers)} ${t('result(s)')}`}
        </h3>
        {emailChangeHistory && (
          <UserResultView
            users={currentUsers}
            emailChangeHistory={false}
            invalidWildCardSearch=""
            cannotSearchEmailChangeHistory={false}
          />
        )}
        {emailChangeHistory && (
          <UserResultView
            users={pastUsers}
            emailChangeHistory
            invalidWildCardSearch={invalidWildCardSearch}
            cannotSearchEmailChangeHistory={cannotSearchEmailChangeHistory}
          />
        )}
        {!emailChangeHistory && currentUsers && <UserResultTable users={currentUsers} />}
      </div>
    );
  }
}
UserSearchResults.propTypes = {
  t: PropTypes.func.isRequired,
  currentUsers: PropTypes.array.isRequired,
  pastUsers: PropTypes.array.isRequired,
  invalidWildCardSearch: PropTypes.string.isRequired,
  emailChangeHistory: PropTypes.bool,
  cannotSearchEmailChangeHistory: PropTypes.bool.isRequired,
};
UserSearchResults.defaultProps = {
  emailChangeHistory: true,
};
export default translate()(UserSearchResults);
