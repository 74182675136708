/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 08:18:39
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-29 09:11:09
 */
import axios from 'axios';
import i18n from './i18n';
import { TokenTypes, EndPoints } from './Constants';
import Console from './Console';
import { logPromiseError } from './Logger';

const t = i18n.t.bind(i18n);

// Takes an array of string or translation functions. The functions are excuted and their result is concatenated.
export const translateArray = (value) => {
  let result = '';
  if (!value) {
    return null;
  }
  value.forEach((val) => {
    if (typeof val === 'function') {
      result += `${val()} `;
    } else {
      result += `${t(val)} `;
    }
  });
  return result.replace(/\s+$/, '');
};

export const getHeaderFromPayLoad = (payload, header) => {
  let result = '';
  if (payload.headers) {
    result = payload.headers[header];
  } else if (payload.response && payload.response.headers) {
    result = payload.response.headers[header];
  }
  return result;
};

export const sendAdminInstructions = (email) => {
  axios.post(`${window.REACT_APP_API_URL}${EndPoints.SendAdminInstructions}`, { email }).catch((error) => {
    Console.log(error);
    logPromiseError('Util.sendAdminInstructions()', error);
  });
};

export const isActivationToken = tokenType => tokenType === TokenTypes.STANDARD_ACTIVATION
  || tokenType === TokenTypes.EMAIL_ONLY_ACTIVATION
  || tokenType === TokenTypes.NEW_PASSWORD_ACTIVATION;
