import axios from 'axios';
import queryString from 'query-string';

import { EndPoints, Headers } from './Constants';
import Console from './Console';
import { getSessionId } from './SessionManager';

const logsUrl = `${window.REACT_APP_API_URL}${EndPoints.Logs}`;
const logsFallbackUrl = `${window.REACT_APP_API_URL}${EndPoints.LogsFallback}`;

export const logError = (error, localStorageDisabled = false) => {
  let sessionId = null;
  if (!localStorageDisabled) {
    sessionId = getSessionId();
  }

  const headers = { 'X-Tableau-SessionId': sessionId };
  const data = {
    code: 'UI Exception',
    level: 'ERROR',
    info: error,
  };
  axios.post(logsUrl, data, { headers }).catch((err) => {
    // Network error, log to console
    Console.log('logError', err);
    const qs = queryString.stringify(data);
    axios.get(`${logsFallbackUrl}?${qs}`, { headers }).catch(() => {
      // This is a fallback, so swallow any errors.
    });
  });
};

export const logInfo = (info, localStorageDisabled = false) => {
  let sessionId = null;
  if (!localStorageDisabled) {
    sessionId = getSessionId();
  }

  const headers = { 'X-Tableau-SessionId': sessionId };
  const data = {
    code: 'UI Event',
    level: 'INFO',
    info,
  };
  axios.post(logsUrl, data, { headers }).catch((err) => {
    // Network error, log to console
    Console.log('logInfo', err);
    const qs = queryString.stringify(data);
    axios.get(`${logsFallbackUrl}?${qs}`, { headers }).catch(() => {
      // This is a fallback, so swallow any errors.
    });
  });
};

export const logWarning = (info, localStorageDisabled = false) => {
  let sessionId = null;
  if (!localStorageDisabled) {
    sessionId = getSessionId();
  }

  const headers = { 'X-Tableau-SessionId': sessionId };
  const data = {
    code: 'UI Warning',
    level: 'WARNING',
    info,
  };
  axios.post(logsUrl, data, { headers }).catch((err) => {
    // Network error, log to console
    Console.log('logInfo', err);
    const qs = queryString.stringify(data);
    axios.get(`${logsFallbackUrl}?${qs}`, { headers }).catch(() => {
      // This is a fallback, so swallow any errors.
    });
  });
};
export const logPromiseError = (methodName, error) => {
  if (error.response) {
    const requestId = error.response.headers[Headers.requestId];
    const code = parseInt(error.response.data.code, 10);
    const logEntry = {
      methodName,
      errorCode: error.response.data.code,
      errorMessage: error.response.data.message,
      requestId,
    };
    if (code > 499) {
      logError(logEntry);
    } else {
      logInfo(logEntry);
    }
    Console.log(`${methodName} Error:
        Code(${error.response.data.code})
        Message(${error.response.data.message})
        RequestId(${requestId})`);
  } else {
    Console.log(`Error in ${methodName}`, {
      errorMessage: error.message,
      errorStack: error.stack,
    });
    logError({
      methodName,
      errorMessage: error.message,
      errorStack: error.stack,
    });
  }
};
