/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 11:23:18
 * @Last Modified by: v-fmasoud@tableau.com
 * @Last Modified time: 2017-11-20 14:29:31
 */
import createHistory from 'history/createBrowserHistory';

export default createHistory({
  // forceRefresh: true,
});
