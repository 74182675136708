/*
 * @Author: v-fmasoud@tableau.com
 * @Date: 2017-10-10 08:18:29
 * @Last Modified by: fmasoud@tableau.com
 * @Last Modified time: 2018-06-28 15:29:46
 */

import * as uuid from 'uuid';
import capitalize from 'capitalize';
import jwtDecode from 'jwt-decode';
import { isEmpty } from 'ramda';

import { HOME_ROUTE } from '../routes';
import { LocalStorageKeys, Scopes } from './Constants';
import Auth from './Auth';
import LocalStorage from './LocalStorage';
import Console from './Console';
import { logError } from './Logger';

const auth = new Auth();

function getClaims(idToken, namespace) {
  // Maps the claims to a nice claims object without the namespace.
  const claimKeys = Object.keys(idToken).filter(k => k.indexOf(namespace) === 0);

  const claims = claimKeys.reduce((newData, key) => {
    const k = key.replace(namespace, '');
    newData[k] = idToken[key];
    return newData;
  }, {});
  return claims;
}

export function createProfile(idToken) {
  idToken = jwtDecode(idToken);
  // Get claims from id token
  const profile = {};

  let claims = getClaims(idToken, 'https://id.tableau.com/claims/');

  /*
  TODO:
  SSO-2170
  We had the wrong namespace for idToken claims.
  This if branch code can be removed after it's deploy and the new rule is deployed.
  */
  if (isEmpty(claims)) {
    claims = getClaims(idToken, 'https://tableausoftware.com/claims/');
  }

  profile.firstName = claims.firstName;
  profile.lastName = claims.lastName;
  profile.uid = claims.uid;
  profile.displayName = capitalize.words(`${profile.firstName} ${profile.lastName}`);
  profile.email = idToken.email;
  profile.scope = claims.scope;
  profile.language = claims.language;
  profile.isTableauOnline = claims.isTableauOnline;
  return profile;
}

export function getProfile() {
  const profile = JSON.parse(LocalStorage.getItem(LocalStorageKeys.profile));
  if (profile) {
    // profile.scope = profile.scope;
    profile.profileReader = profile.scope.includes(Scopes.readProfiles) && !profile.scope.includes(Scopes.updateProfiles);
    profile.profileAdmin = profile && profile.scope && profile.scope.includes(Scopes.updateProfiles);
    profile.profileAdminWithGrant = profile.profileAdmin && profile.scope.includes(Scopes.permissionGranters);
    profile.showEmail = profile && profile.scope && profile.scope.includes(Scopes.emailViewers);
    profile.showGroups = profile && profile.scope && profile.scope.includes(Scopes.readProfileGroups);
    profile.tabOnlineServerGroupAdmin = profile && profile.scope && profile.scope.includes(Scopes.updateTableauOnlineServerAdmins);
  }
  return profile;
}

export function clearSession() {
  LocalStorage.removeItem(LocalStorageKeys.expires);
  LocalStorage.removeItem(LocalStorageKeys.profile);
  LocalStorage.removeItem(LocalStorageKeys.token);
  LocalStorage.removeItem(LocalStorageKeys.passwordChangeStatus);
}

export function setProfile(authResult) {
  // Set the time that the access token will expire at
  const tokenExpiresInMilliSeconds = authResult.expiresIn * 1000;
  const tokenExpiresAtInMilliSeconds = JSON.stringify(tokenExpiresInMilliSeconds + new Date().getTime());

  LocalStorage.setItem(LocalStorageKeys.token, authResult.accessToken);
  LocalStorage.setItem(LocalStorageKeys.expires, tokenExpiresAtInMilliSeconds);
  const profile = createProfile(authResult.idToken);
  LocalStorage.setItem(LocalStorageKeys.language, profile.language);
  LocalStorage.setItem(LocalStorageKeys.profile, JSON.stringify(profile));
  LocalStorage.setItem(LocalStorageKeys.passwordChangeStatus, false);
}

export function setSessionId() {
  try {
    sessionStorage.setItem(LocalStorageKeys.sessionId, uuid.v4());
  } catch (ex) {
    Console.log('SessionManager.setSessionId()', ex.code, ex.message);
    logError(
      {
        errorCode: ex.code,
        errorMessage: ex.message,
        callingMethod: 'SessionManager.setSessionId()',
      },
      true,
    );
  }
}

export function getSessionId() {
  let sessionValue = null;
  try {
    sessionValue = sessionStorage.getItem(LocalStorageKeys.sessionId);
  } catch (ex) {
    Console.log('SessionManager.getSessionid()', ex.code, ex.message);
    logError(
      {
        errorCode: ex.code,
        errorMessage: ex.message,
        callingMethod: 'SessionManager.getSessionId()',
      },
      true,
    );
  }
  return sessionValue;
}

export function setState(goTo) {
  const stateId = uuid.v4();
  goTo = goTo || HOME_ROUTE;
  LocalStorage.setItem(stateId, JSON.stringify({ goTo }));
  return stateId;
}

export function getState(stateId) {
  // let path = HOME_ROUTE;
  let state = null;
  if (stateId) {
    state = JSON.parse(LocalStorage.getItem(stateId));
    LocalStorage.removeItem(stateId);
  }
  return state;
}

function validLocalSession() {
  const tokenExpiresAtInMilliSeconds = JSON.parse(LocalStorage.getItem(LocalStorageKeys.expires)) || 0;
  const bufferInMilliSeconds = 0.5 * 60000; // half a minute
  const currentTimeWithBuffer = new Date().getTime() + bufferInMilliSeconds;
  const token = LocalStorage.getItem(LocalStorageKeys.token);
  const result = token && token !== '' && getProfile() && currentTimeWithBuffer < tokenExpiresAtInMilliSeconds;

  if (result) {
    // If a token is present in the session, validate it
    const passwordChangedByUser = JSON.parse(
      LocalStorage.getItem(LocalStorageKeys.passwordChangeStatus) !== null
        && LocalStorage.getItem(LocalStorageKeys.passwordChangeStatus),
    );
    if (passwordChangedByUser) {
      // Skip token renew, if the user itself has changed password
      return true;
    }
    auth
      .renewAuth()
      .then(() => true)
      .catch(err => false);
  } else {
    return false;
  }
}

function publicSession(){
  const publicSession = LocalStorage.getItem(LocalStorageKeys.publicSession);
  return publicSession === "true";
}
export function isAuthenticated() {
  return new Promise((resolve, reject) => {
    if (publicSession() || validLocalSession()) {
      resolve(true);
    } else {
      clearSession();
      auth
        .renewAuth()
        .then(() => resolve(true))
        .catch(err => reject(err));
    }
  });
}

export function getAccessToken() {
  return new Promise((resolve, reject) => {
    isAuthenticated()
      .then(() => resolve(LocalStorage.getItem(LocalStorageKeys.token)))
      .catch(() => reject(new Error('User is not authenticated.')));
  });
}

export const getHeaders = (language, clientId, goto, relayState) => new Promise((resolve) => {
  let accessToken = null;
  language = language || 'en';
  clientId = clientId || '';
  goto = goto || '';
  relayState = relayState || '';
  const sessionId = getSessionId() || '';
  getAccessToken()
    .then((value) => {
      accessToken = value;
      const header = {
        Authorization: `Bearer ${accessToken}`,
        'X-Tableau-SessionId': sessionId,
        'X-Tableau-Language': language,
        'X-Tableau-ClientId': clientId,
        'X-Tableau-Goto': goto,
        'X-Tableau-Relay-State': relayState,
        'Hashed-Email' : publicSession() ? LocalStorage.getItem(LocalStorageKeys.hashedEmail) : null
      }
      if(header["Hashed-Email"] == null){
        delete header["Hashed-Email"];
      }
      resolve(header);
    })
    .catch(() => resolve({
      'X-Tableau-SessionId': sessionId,
      'X-Tableau-Language': language,
      'X-Tableau-ClientId': clientId,
      'X-Tableau-Goto': goto,
      'X-Tableau-Relay-State': relayState,
    }));
});
